<template>
  <section>
    <v-card class="px-4 py-4">
      <v-card-title class="px-0 py-0 mb-4 justify-end">
        <v-btn dark color="secondary" @click="crearSubGrupo">Agregar</v-btn>
      </v-card-title>
      <v-card-text class="px-0 py-0 mb-2">
        <data-table-component 
          v-models:select="perPage" 
          v-models:pagina="page" 
          :headers="headers" 
          :items="items"
          @paginar="paginate" 
          :total_registros="totalRows"
        >
          <template v-slot:[`item.activo`]="{ item }">
            <v-chip :class="item.activo ? 'secondary ' : ''" :style="item.activo ? 'color:white !important' : ''">
              {{ item.activo ? "Activo" : "Inactivo" }}
            </v-chip>
          </template>
          <template v-slot:[`item.acciones`]="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon x-small color="primary" @click.stop="handleViewCategoria(item)">mdi-archive-eye</v-icon>
                <v-icon @click="editarSubgrupo(item)" v-bind="attrs" v-on="on" large color="primary">
                  mdi-pencil
                </v-icon>
              </template>

              <span>Editar</span>
            </v-tooltip>


            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon @click="(deleteDialog = true), (subgrupoSeleccionada = item)" v-bind="attrs" v-on="on" large
                        color="primary">
                  mdi-delete
                </v-icon>
              </template>

              <span>Eliminar</span>
            </v-tooltip>


            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon
                      @click="(modalEstado = true), (subgrupoSeleccionada = item)" :disabled="!haveRoles([
                    'ROLE_DIRECCION_COMPRAS',
                    'ROLE_TECNICO_DIRECCION_COMPRAS',
                  ])
                    ">
                  <v-icon>{{
                      item.activo
                          ? "mdi-lock-outline"
                          : "mdi-lock-open-outline"
                    }}
                  </v-icon>
                </v-btn>
              </template>
              <span> Deshabilitar </span>
            </v-tooltip>
          </template>
        </data-table-component>
      </v-card-text>
    </v-card>

    <UpsertSubGrupo 
      :isOpen="upsertModalAbierta" 
      :editting="editable"
      :editItem="editItem"
      @close="upsertModalAbierta = false" 
      :isLoading="upsertAction.isLoading"
      @save="guardarSubgrupo"
    />

    <ConfirmationModalComponent 
      :is-open="modalEstado"
      :is-loading="toggleStatus.isLoading"
      :title="`¿Está seguro de ${subgrupoSeleccionada?.activo ? 'deshabilitar' : 'habilitar'} el subgrupo?`" 
      description="Esta acción afectará la estructura de productos del catálogo"
      ok-text="Aceptar"
      @cancel="modalEstado = false" 
      @confirm="deshabilitar"
    />

    <ConfirmationModalComponent 
      :is-open="deleteDialog" 
      :is-loading="deleteAction.isLoading"
      title="¿Deseas remover esta subgrupo?"
      description="De confirmarse la siguiente acción, los cambios no serán reversibles."
      ok-text="Aceptar"
      @cancel="(deleteDialog = false), (subgrupoSeleccionada = null)" 
      @confirm="eliminarSubgrupo"
    />
    <CategoriaDetalleModal :isOpen="isDetailsModalOpen" :subgrupo="editItem" @close="isDetailsModalOpen = false" />
  </section>
</template>
<script>
import { createLoadable, isResponseSuccesful, setLoadableResponse, toggleLoadable } from "@/utils/loadable";
import DataTableComponent from "@/components/DataTableComponent.vue";
import { ConfirmationModalComponent } from '@/components/utils';
import UpsertSubGrupo from './subGrupo/UpsertSubGrupo.vue';
import { Observable, Listener } from '@/utils/observable.js';
import { Countdown } from '@/utils/countdown';
import CategoriaDetalleModal from "./subGrupo/CategoriaDetalleModal.vue";

export default {
  name: "SubgruposComponent",
  components: { DataTableComponent, ConfirmationModalComponent, UpsertSubGrupo, CategoriaDetalleModal },
  data: () => ({
    headers: [
      {
        text: "Código familia",
        value: "CmGrupo.CmFamilium.codigo",
        align: "center",
      },
      {
        text: "Código grupo",
        value: "CmGrupo.codigo",
        align: "center",
      },
      {
        text: 'OBS',
        value: 'Ob.nombre',
        sortable: false,
        align: "center",
      },
      {
        text: "Código subgrupo",
        value: "codigo",
        align: "center",
      },
      {
        text: "Nombre",
        value: "nombre",
        align: "center",
      },

      {
        text: "Estado",
        value: "activo",
        align: "center",
      },
      {
        text: "Acciones",
        value: "acciones",
        align: "center",
        sortable: false,
      },
    ],
    items: [],
    totalRows: 0,
    page: 1,
    perPage: 10,
    tabMenu: null,
    deleteDialog: false,
    modalEstado: false,
    subgrupoSeleccionada: null,
    editable: false,
    upsertModalAbierta: false,
    editItem: null,
    toggleStatus: createLoadable(null),
    deleteAction: createLoadable(null),
    upsertAction: createLoadable(null),
    isDetailsModalOpen: false,
  }),
  methods: {
    async deshabilitar() {
      toggleLoadable(this.toggleStatus);
      const { data } = await this.services.CmSubgrupos.patchSubgruposEstado(this.subgrupoSeleccionada.id, { activo: !this.subgrupoSeleccionada.activo });
      
      setLoadableResponse(this.toggleStatus, data, { showAlertOnSuccess: true, skipOnSuccess: true });
      this.modalEstado = false;
      this.subgrupoSeleccionada = null;
      
      if (isResponseSuccesful(data)) this.getSubgrupos();
    },
    async eliminarSubgrupo() {
      toggleLoadable(this.deleteAction);
      const { data } = await this.services.CmSubgrupos.deleteSubgrupos(this.subgrupoSeleccionada.id);

      setLoadableResponse(this.deleteAction, data, { showAlertOnSuccess: true, skipOnSuccess: true });
      this.subgrupoSeleccionada = null;
      this.deleteDialog = false;

      if (isResponseSuccesful(data)) this.getSubgrupos();
    },
    async getSubgrupos() {
      const {status, data, headers} =
          await this.services.CmSubgrupos.getSubgruposList({
            page: this.page,
            per_page: this.perPage,
          });

      if (status == 200) {

        this.items = data.data;
        this.totalRows = Number(headers.total_rows);
      }
    },
    async getGrupos() {
            const {status, data} = await this.services.CmGrupos.getGruposList({
                pagination: false,
            });

            if (status == 200) this.group_list = data.data;
        },
    paginate(params) {
      const {cantidad_por_pagina, pagina} = params;
      this.perPage = cantidad_por_pagina;
      this.page = pagina;

      this.getSubgrupos();
    },
    clearInfo() {
      this.editable = false;
      this.editItem = null;
    },
    async guardarSubgrupo(subGrupoData) {
      this.upsertModalAbierta = false;
      toggleLoadable(this.upsertAction);

      const action = this.editable 
        ? () => this.services.CmSubgrupos.putSubgrupos(this.editItem.id, subGrupoData)
        : () => this.services.CmSubgrupos.postSubgrupos({ ...subGrupoData, activo: true });

      const { data } = await action();

      setLoadableResponse(this.upsertAction, data, { showAlertOnSuccess: true, skipOnSuccess: true });
      this.clearInfo();

      if (isResponseSuccesful(data)) this.getSubgrupos();
    },
    handleViewCategoria(subgrupo) {
      this.editItem = subgrupo;
      this.isDetailsModalOpen = true;
    },
    editarSubgrupo(item) {
      this.editable = true;
      this.editItem = item;
      this.upsertModalAbierta = true;
    },
    crearSubGrupo() {
      this.upsertModalAbierta = true;
      this.editable = false;
      this.editItem = null;
    },
  },
  created() {
    this.getSubgrupos();
  },
  mounted() {
    const listener = new Listener('famComponent').setHandler(() => this.getSubgrupos());
    this.unsubscribe = Observable.getInstance().subscribe('refresh-metodos-cm', listener);
  },
  beforeDestroy() {
    Countdown.getInstance().clear();
    this.unsubscribe?.();
  },
};
</script>
