<template>
  <section>
    <v-container>
      <p class="text-h6 secondary--text">Estructura de productos de catálogo</p>
      <v-tabs v-model="tabMenu" background-color="bgMinsal" show-arrows>
        <v-tabs-slider color="secondary"></v-tabs-slider>
        <v-tab
            v-for="(item, index) in tabs"
            :key="index"
            class="text-no-style secondary--text"
        >
          <v-icon> {{ item.icon }}</v-icon>
          {{ item.nombre }}
        </v-tab>
      </v-tabs>
      <v-tabs-items class="mt-4 mx-3" :value="tabMenu" style="width: 100%">
        <div v-for="(item, index) in tabs" :key="index">
          <v-tab-item>
            <component :is="item.content"/>
          </v-tab-item>
        </div>
      </v-tabs-items>
    </v-container>
  </section>
</template>
<script>
import FamiliaComponent from "./components/cmFamilia.vue";
import GrupoComponent from "./components/cmGrupos.vue";
import SubGrupoComponent from "./components/cmSubGrupos.vue";
import ProductoComponent from "./components/producto/cmProducto.vue";

export default {
  name: "CmEstructuraView",
  components: {
    FamiliaComponent,
    GrupoComponent,
    SubGrupoComponent,
    ProductoComponent,
  },
  data: () => ({
    tabMenu: null,
    tabs: [
      {
        icon: 'mdi-clipboard-check',
        nombre: 'Familia',
        content: FamiliaComponent,
        value: '1'
      },
      {
        icon: 'mdi-clipboard-text-clock-outline',
        nombre: 'Grupo',
        content: GrupoComponent,
        value: '2'
      },
      {
        icon: 'mdi-clipboard-text-clock-outline',
        nombre: 'Sub Grupo',
        content: SubGrupoComponent,
        value: '3'
      },
      {
        icon: 'mdi mdi-shopping',
        nombre: 'Producto',
        content: ProductoComponent,
        value: '4'
      },
    ]
  }),
};
</script>
