<template>
    <v-form ref="form" @submit.prevent="submit()" class="mt-4">
        <div class="d-flex align-center">
            <p class="mr-2">C:</p>
            <v-text-field 
                v-model="form.categoria_c_ini" 
                class="mr-2 flex-grow-1 flex-shrink-1" 
                label="Inicio" 
                outlined 
                type="number"
                :error-messages="categoria_a_iniErrors"
                @change="$v.form.categoria_c_ini.$touch()"
                @blur="$v.form.categoria_c_ini.$touch()"
            />
            <v-text-field
                v-model="form.categoria_c_fin" 
                class="flex-grow-1 flex-shrink-1" 
                label="Fin" 
                outlined 
                type="number"
                :error-messages="categoria_c_finErrors"
                @change="$v.form.categoria_c_fin.$touch()"
                @blur="$v.form.categoria_c_fin.$touch()"
            />
        </div>
        <div class="d-flex align-center">
            <p class="mr-2">B:</p>
            <v-text-field 
                v-model="form.categoria_b_ini" 
                class="mr-2 flex-grow-1 flex-shrink-1" 
                label="Inicio" 
                outlined 
                type="number"
                :error-messages="categoria_b_iniErrors"
                @change="$v.form.categoria_b_ini.$touch()"
                @blur="$v.form.categoria_b_ini.$touch()"
            />
            <v-text-field 
                v-model="form.categoria_b_fin" 
                class="flex-grow-1 flex-shrink-1" 
                label="Fin" 
                outlined 
                type="number"
                :error-messages="categoria_b_finErrors"
                @change="$v.form.categoria_b_fin.$touch()"
                @blur="$v.form.categoria_b_fin.$touch()"
            />
        </div>
        <div class="d-flex align-center">
            <p class="mr-2">A:</p>
            <v-text-field 
                v-model="form.categoria_a" 
                class="mr-2 flex-grow-1 flex-shrink-1" 
                label="Inicio" 
                outlined 
                type="number"
                :error-messages="categoria_aErrors"
                @change="$v.form.categoria_a.$touch()"
                @blur="$v.form.categoria_a.$touch()"
            />
        </div>
        <div class="d-flex justify-end mt-4">
            <v-btn class="flex-grow-1 flex-shrink-1" @click.stop="close" color="secondary">Atrás</v-btn>
            <v-btn class="flex-grow-1 flex-shrink-1 ml-4" type="submit" color="primary">Finalizar</v-btn>
        </div>
    </v-form>
</template>
<script>
import { validationMixin } from 'vuelidate';
import { required, minValue } from 'vuelidate/lib/validators';
import { Validator } from '@/utils/form-validation.js';

export default {
    name: 'EspecificacionesForm',
    mixins: [validationMixin],
    props: {
        onClose: { type: Function, },
        editItem: { type: Object, required: false },
        isParentModalOpen: { type: Boolean },
    },
    validations(){
        const getMin = (threshold) => threshold ? Number(threshold) + 1 : 1;

        return ({
            form: {
                categoria_c_ini: { required, minValue: minValue(1) },
                categoria_c_fin: { required, minValue: minValue(getMin(this.form.categoria_c_ini)), },
                categoria_b_ini: { required, minValue: minValue(getMin(this.form.categoria_c_fin)), },
                categoria_b_fin: { required, minValue: minValue(getMin(this.form.categoria_b_ini)), },
                categoria_a: { required, minValue: minValue(getMin(this.form.categoria_b_fin)), },
            },
        });
    },
    data: () => ({
        form: {
            categoria_c_ini: null,
            categoria_c_fin: null,
            categoria_b_ini: null,
            categoria_b_fin: null,
            categoria_a: null,
        },
    }),
    computed: {
        categoria_a_iniErrors() {
            return new Validator(this.$v.form.categoria_c_ini).detect().getResult();
        },
        categoria_c_finErrors() {
            return new Validator(this.$v.form.categoria_c_fin).required()
                .minValue('El valor debe ser mayor al inicio de la espec. A ({VALUE})').getResult();
        },
        categoria_b_iniErrors() {
            return new Validator(this.$v.form.categoria_b_ini).required()
                .minValue('El valor debe ser mayor al fin de la espec. A ({VALUE})').getResult();
        },
        categoria_b_finErrors() {
            return new Validator(this.$v.form.categoria_b_fin).required()
                .minValue('El valor debe ser mayor al inicio de la espec. B ({VALUE})').getResult();
        },
        categoria_aErrors() {
            return new Validator(this.$v.form.categoria_a).required()
                .minValue('El valor debe ser mayor al fin de la espec. B ({VALUE})').getResult();
        },
    },
    methods: {
        clearForm() {
            this.$v.$reset();
            this.$refs.form.reset();
        },
        close() {
            this.$props.onClose();
        },
        submit() {
            this.$v.$touch();

            if (this.$v.$invalid) return;
            this.$emit('finish', this.form);
        },
        fillControls(value) {
            const formCopy = {...this.form};
            
            formCopy.categoria_b_fin = value?.categoria_b_fin;
            formCopy.categoria_b_ini = value?.categoria_b_ini;
            formCopy.categoria_c_fin = value?.categoria_c_fin;
            formCopy.categoria_c_ini = value?.categoria_c_ini;
            formCopy.categoria_a = value?.categoria_a;
            this.form = formCopy;
        },
    },
    watch: {
        editItem(value) {
            this.fillControls(value);
        },
        isParentModalOpen(value) {
            if (value) this.fillControls(this.$props.editItem);
        },
    },
    created() {
        this.fillControls(this.$props.editItem);
    },
}
</script>