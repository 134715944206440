<template>
    <div>
        <SpinnerComponent :spinning="crudAction.isLoading" text="Cargando..." />
        <v-card class="px-4 py-4">
            <v-card-title class="px-0 py-0 mb-4">
                <div class="d-flex align-end flex-column-reverse flex-sm-row" style="width: 100%; gap: 16px;">
                    <DebouncedSearchComponent 
                        @search="handleSearch" 
                        placeholder="Buscar por nombre"
                        class="cm-producto-search d-block flex-grow-1 flex-shrink-1 mt-0 pt-0"
                        hide-details
                        clearable
                        style="max-width: 400px;"
                    />
                    <v-btn 
                        dark 
                        color="secondary" 
                        class="cm-producto-btn flex-grow-1 flex-sm-grow-0 flex-shrink-1 ml-auto"  
                        @click="generarKardexPrecio"
                        :loading="generacionKardexPrecio.isLoading"
                    >
                        Descargar Kardex precio
                    </v-btn>
                    <v-btn 
                        dark 
                        color="secondary" 
                        class="cm-producto-btn flex-grow-1 flex-sm-grow-0 flex-shrink-1"  
                        @click="generarKardexProducto"
                        :loading="generacionKardexProducto.isLoading"
                    >
                        Descargar Kardex producto
                    </v-btn>
                    <!-- <v-btn 
                        dark 
                        color="secondary" 
                        class="cm-producto-btn flex-grow-1 flex-sm-grow-0 flex-shrink-1"  
                        @click="abrirModalOferta"
                    >
                        Cargar ofertas
                    </v-btn> -->
                    <v-btn 
                        dark 
                        color="secondary" 
                        class="cm-producto-btn flex-grow-1 flex-sm-grow-0 flex-shrink-1"  
                        @click="openInsertModal"
                    >
                        Agregar
                    </v-btn>
                </div>
            </v-card-title>
            <v-card-text class="px-0 py-0 mb-2">
                <DataTableComponent
                    class="product-table"
                    :headers="headers"
                    :items="products.data"
                    :loading="products.isLoading"
                    @paginar="onPaginationChange"
                    :total_registros="total"
                    v-models:select="pageSize"
                    v-models:pagina="currentPage"
                >
                    <template v-slot:item.sku="{ item }">
                        {{ item.cmsku?.sku ?? '-' }}
                    </template>
                    <template v-slot:item.obs="{ item }">
                        {{ `${item.CmSubGrupo.Ob.codigo}-${item.CmSubGrupo.Ob.nombre}` }}
                    </template>
                    <template v-slot:item.foto="{ item }">
                        <v-img class="my-2" max-height="70" max-width="70" :src="item.foto" v-if="item.foto && item.foto !== '0'" />
                        <v-icon v-else class="my-2">mdi mdi-image</v-icon>
                    </template>
                    <template v-slot:item.activo="{ item }">
                        <v-chip :class="item.activo ? 'secondary ' : ''" :style="item.activo ? 'color:white !important' : ''" >
                            {{ item.activo ? "Activo" : "Inactivo" }}
                        </v-chip>
                    </template>
                    <template v-slot:item.actions="{ item }">
                        <div class="d-flex justify-center" style="gap: 5px;">
                            <v-icon x-small color="primary" @click.stop="handleViewPrecio(item)">mdi mdi-cash-multiple</v-icon>
                            <v-icon x-small color="primary" @click.stop="handleViewProduct(item)">mdi-archive-eye</v-icon>
                            <v-icon x-small color="primary" @click.stop="handleProductEdit(item)">mdi-pencil</v-icon>
                            <v-icon x-small color="primary" @click.stop="handleProductRemove(item)">
                                mdi-delete
                            </v-icon>
                            <v-icon x-small color="primary" @click.stop="handleToggleStatus(item)">
                                {{`mdi-lock-${item.activo ? 'outline' : 'open-outline'}`}}
                            </v-icon>
                        </div>
                    </template>
                </DataTableComponent>
            </v-card-text>
        </v-card>
        <UpsertProductModal 
            :isOpen="isUpsertModalOpen" 
            @close="handleUpsertModalClose" 
            @save="handleProductSave" 
            @on-success="fetchProducts"
            :product="producto.data"
            :editting="isEditting"
            :cargando="producto.isLoading"
        />
        <FeedbackModalComponent 
            :isOpen="isFeedbackOpen"
            type="success" 
            :description="feedbackText"
            buttonText="Cerrar"
            @on-close="handleFeedbackClose"
        />
        <ProductDetailsModal :isOpen="isDetailsModalOpen" :product="activeProduct" @close="isDetailsModalOpen = false"/>

        <CmPrecioModal
            :isOpen="isDetailsModalPrecioOpen" 
            :precios="precios" 
            @refresh-ofertas="refrescarOfertas"
            @close="isDetailsModalPrecioOpen = false" 
            :producto="activeProduct"
            @on-save="cerrarModalOferta"
        />
        <CmCargarOfertasModal 
            :isOpen="modalOferta"
            :proveedores="proveedor_list"
            @buscarProveedor="buscarProveedor"
            @cargarMasProveedores="cargarMasProveedores"
            @close="modalOferta = false"
        />
        <ConfirmationModalComponent  
            :isOpen="isConfirmModalOpen" 
            :description="confirmationText"
            @confirm="performCrudOperation" 
            @cancel="isConfirmModalOpen = false"
            :isLoading="crudAction.isLoading"
        />
    </div>
</template>
<script>
import { 
    createPageable, 
    createLoadable, 
    togglePageable,
    toggleLoadable,
    setPageableResponse, 
    setLoadableResponse,
    isResponseSuccesful,
    PAGINATION_PROPERTY_NAMES,
} from '@/utils/loadable.js';
import UpsertProductModal from './UpsertProductModal.vue';
import { ConfirmationModalComponent, FeedbackModalComponent } from '@/components/utils';
import { DebouncedSearchComponent, SpinnerComponent } from '@/components/utils';
import ProductDetailsModal  from './ProductDetailsModal.vue';
import DataTableComponent from "@/components/DataTableComponent.vue";
import { injectAction } from '@/utils/text';
import { Fragment } from 'vue-fragment';
import CmPrecioModal from './CmPrecioModal.vue';
import CmCargarOfertasModal from './CmCargarOfertasModal.vue';
import moment from 'moment';

const pageSizeProp = PAGINATION_PROPERTY_NAMES.PAGE_SIZE;
const currentPageProp = PAGINATION_PROPERTY_NAMES.CURRENT_PAGE; 

export default {
    name: 'cmProducto',
    components: {
    Fragment,
    UpsertProductModal,
    ConfirmationModalComponent,
    FeedbackModalComponent,
    DebouncedSearchComponent,
    SpinnerComponent,
    DataTableComponent,
    ProductDetailsModal,
    CmPrecioModal,
    CmCargarOfertasModal
},
    data: () => ({
        products: createPageable([], 5),
        crudAction: createLoadable(null),
        statusToggling: createLoadable(null),
        generacionKardexPrecio: createLoadable(null),
        generacionKardexProducto: createLoadable(null),
        pagination: {
            [pageSizeProp]: 10,
            [currentPageProp]: 1,
        },
        producto: createLoadable(null),
        precios: createLoadable(null),
        // UI
        isDetailsModalOpen: false,
        isDetailsModalPrecioOpen: false,
        isUpsertModalOpen: false,
        isConfirmModalOpen: false,
        isFeedbackOpen: false,
        // Table
        headers: [
            { align: 'center', sortable: false, text: 'Foto', value: 'foto' },
            { align: 'Obs', sortable: false, text: 'Obs', value: 'obs' },
            { align: 'center', sortable: true, text: 'SKU', value: 'sku', },
            { align: 'center', sortable: true, text: 'Descripción', value: 'descripcion', },
            { align: 'center', sortable: true, text: 'Nombre', value: 'nombre', },
            { align: 'center', sortable: true, text: 'Precio promedio', value: 'precio_promedio', },
            { align: 'center', sortable: true, text: 'Disponibilidad', value: 'disponibilidad', },
            { align: 'center', sortable: true, text: 'Ventas perdidas', value: 'ventas_perdidas', },
            { align: 'center', sortable: false, text: 'Estado', value: 'activo', },
            { align: 'center', sortable: false, text: 'Acciones', value: 'actions', props: { class: 'fixed-column', }, },
        ],
        // Data
        searchTerm: null,
        activeAction: null,
        upsertData: null,
        activeProduct: null,
        modalOferta: false,
        proveedor_list: createPageable([], 30),
        paginacionProveedores: {
            pagina: 1,
            registrosPorPagina: 30,
        },
    }),
    computed: {
        total() {
            return this.products.pagination[PAGINATION_PROPERTY_NAMES.COUNT];
        },
        pageSize() {
            return this.products.pagination[pageSizeProp];
        },
        currentPage() {
            return this.products.pagination[currentPageProp];
        },
        feedbackText() {
            return injectAction('Producto {RESULT} con éxito', this.activeAction);
        },
        confirmationText() {
            switch (this.activeAction) {
                case 'delete':
                    return '¿Está seguro de eliminar este producto?';
                case 'toggle-status':
                    return '¿Desea cambiar de estado?'
                default:
                    return '¿Desea continuar con la acción?'
            };
        },
        isEditting() {
            return this.activeAction === 'edit';
        },
    },
    methods: {
        cerrarModalOferta(oferta) {
            this.refrescarOfertas(oferta.id_producto);
            // this.isDetailsModalPrecioOpen = false; 
        },
        async fetchProducts() {
            togglePageable(this.products);
            const filters = {
                ...this.pagination,
                nombre: this.searchTerm,
            };

            const { data, headers } = await this.services.Product.getProducts(filters);
            setPageableResponse(this.products, data, headers);
        },
        onPaginationChange(newPagination) {
            const { cantidad_por_pagina, pagina } = newPagination;

            this.pagination = {
                [pageSizeProp]: cantidad_por_pagina,
                [currentPageProp]: pagina,
            };

            this.fetchProducts();
        },
        handleProductSave(data) {
            this.upsertData = data;
            this.isUpsertModalOpen = false;
            this.isConfirmModalOpen = true;
        },
        handleViewProduct(product) {
            this.activeProduct = product;
            this.isDetailsModalOpen = true;
        },
        handleViewPrecio(product) {
            this.activeProduct = product;
            this.obtenerPrecioProducto(product);
            this.isDetailsModalPrecioOpen = true;
        },
        handleProductEdit(product) {
            this.activeAction = 'edit';
            this.activeProduct = product;
            this.obtenerProducto(product);
            this.isUpsertModalOpen = true;
        },
        async obtenerProducto(producto) {
            toggleLoadable(this.producto);            
            const { data } = await this.services.cmProducto.obtenerProducto(producto.id);
            setLoadableResponse(this.producto, data);
        },
        async obtenerPrecioProducto(producto) {
            toggleLoadable(this.precios);    
            const { data } = await this.services.cmProducto.obtenerPrecioProducto(producto.id);
            setLoadableResponse(this.precios, data);

            if (isResponseSuccesful(data) && Array.isArray(data.data)) {
                const ofertasActualizadas = data.data;

                const productosActualizados = this.products.data.slice();
                if (ofertasActualizadas.length > 0) {
                    ofertasActualizadas.map((oferta) => {
                        const productoOferta = oferta.producto;    
                        const indexProductoEnLista = productosActualizados.findIndex((p) => p.id === productoOferta.id);
    
                        if (indexProductoEnLista !== -1) {
                            productosActualizados[indexProductoEnLista].precio_promedio = productoOferta.precio_promedio;
                            productosActualizados[indexProductoEnLista].disponibilidad = productoOferta.disponibilidad;
                        }
                    });
                } else {    
                    const indexProductoEnLista = productosActualizados.findIndex((p) => p.id === producto.id);

                    if (indexProductoEnLista !== -1) {
                        productosActualizados[indexProductoEnLista].precio_promedio = 0;
                        productosActualizados[indexProductoEnLista].disponibilidad = 0;
                    }
                }

                this.products.data = productosActualizados;
            }
        },
        handleProductRemove(product) {
            this.activeProduct = product;
            this.activeAction = 'delete';
            this.isConfirmModalOpen = true;
        },
        handleToggleStatus(product) {
            this.activeAction = 'toggle-status';
            this.activeProduct = product;
            this.isConfirmModalOpen = true;
        },
        openInsertModal() {
            this.producto = createLoadable(null);
            this.activeAction = 'insert';
            this.activeProduct = null;
            this.isUpsertModalOpen = true;
        },
        handleFeedbackClose() {
            this.activeAction = null;
            this.isFeedbackOpen = false;
        },
        async performCrudOperation() {
            const productId = this.activeAction !== 'insert' ? this.activeProduct.id : null;
            let action = null;

            switch (this.activeAction) {
                case 'delete':
                    action = () => this.services.Product.deleteProduct(productId)
                break;
                case 'toggle-status':
                    action = () => this.services.Product.toggleStatus(productId);
                break;
                default:
                    action = () => this.services.Product.upsertProduct(this.upsertData, productId);
                break;
            }

            // Ejecucion
            toggleLoadable(this.crudAction);
            const { data } = await action();
            this.isConfirmModalOpen = false;
            setLoadableResponse(this.crudAction, data);

            if (!isResponseSuccesful(data)) return;

            this.isFeedbackOpen = true;
            this.upsertData = null;
            this.fetchProducts();
        },
        handleSearch(term) {
            this.searchTerm = term;
            this.pagination[currentPageProp] = 1;
            this.fetchProducts();
        },
        handleUpsertModalClose() {
            this.isUpsertModalOpen = false;
            this.activeProduct = null;
        },
        refrescarOfertas(oferta){
            this.obtenerPrecioProducto({ id: oferta });
        },
        
        async buscarProveedor(termino) {
            if (this.filtroProveedor === termino) return;
            this.filtroProveedor = termino;
            this.proveedor_list.data = [];
            this.paginacionProveedores.pagina = 1;
            this.obtenerProveedores();
        },
        async cargarMasProveedores() {
            if (this.proveedor_list.isLoading) return;
            const { page, per_page, total_rows } = this.proveedor_list.pagination;
            const currentlyLoaded = page * per_page;
            if (!(currentlyLoaded < total_rows)) return;

            this.paginacionProveedores.pagina = page + 1;
            this.paginacionProveedores.registrosPorPagina = per_page;
            this.obtenerProveedores();
        },
        async obtenerProveedores() {
            const filtros = { 
                pagination: true, 
                per_page: this.paginacionProveedores.registrosPorPagina,
                page: this.paginacionProveedores.pagina,
                busqueda: this.filtroProveedor,
            };

            const itemsCopy = this.proveedor_list.data;
            togglePageable(this.proveedor_list);
            const { data, headers} = await this.services.cmProducto.listProductoProveedor(null, filtros);
            setPageableResponse(this.proveedor_list, data, headers, { skipOnSuccess: true, persistDataOnError: true });
            
            if (!isResponseSuccesful(data)) {
                // Hacemos rollback de la pagina en caso de error para volver a cargar la misma al scrollear
                const { pagina } = this.paginacionProveedores;
                this.paginacionProveedores.pagina = pagina > 1 ? pagina - 1 : 1; 
                return;
            };

            this.proveedor_list.data = filtros.page > 1 ? itemsCopy.concat(data.data) : data.data;
        },
        abrirModalOferta(){
            this.modalOferta = true;
            this.obtenerProveedores();
        },
        async generarKardexPrecio(){
            toggleLoadable(this.generacionKardexPrecio);
            const { data } = await this.services.cmProducto.descargarKardexPrecio();
            setLoadableResponse(this.generacionKardexPrecio, data, { skipOnSuccess: true, isFile: true });

            if (data && !('error' in data)) {
                const file = new File([data], `kardex-precio-${moment().format('DDMMYYYYHHmm')}.xlsx`,
                    { type: "application/octet-stream" },
                );

                const link = document.createElement("a");
                link.href = window.URL.createObjectURL(file);
                link.download = file.name;
                link.click();            
            }
        },
        async generarKardexProducto(){
            toggleLoadable(this.generacionKardexProducto);
            const { data } = await this.services.cmProducto.descargarKardexProducto();
            setLoadableResponse(this.generacionKardexProducto, data, { skipOnSuccess: true, isFile: true });

            if (data && !('error' in data)) {
                const file = new File([data], `kardex-producto-${moment().format('DDMMYYYYHHmm')}.xlsx`,
                    { type: "application/octet-stream" },
                );

                const link = document.createElement("a");
                link.href = window.URL.createObjectURL(file);
                link.download = file.name;
                link.click();            
            }
        }
    },
    created() {
        this.fetchProducts();
    },
};
</script>
<style>
    .product-table thead tr th:last-child,
    .product-table tbody tr td:last-child {
        position: sticky;
        right: 0;
    }

    .product-table tbody tr td:last-child {
        background-color: var(--v-bgMinsal-base);
    }

    .cm-producto-search,
    .cm-producto-btn {
        display: block;
        width: 100%;
    }

    @media (min-width: 600px) {
        .cm-producto-search,
        .cm-producto-btn {
            display: inline-block !important;
            width: auto;
        }
    }
    
</style>