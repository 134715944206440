<template>
    <v-form @submit.prevent="submit()">
        <template v-if="product.foto">
            <v-alert dense text type="info">
                Puedes subir una nueva foto o mantener la foto actual:
            </v-alert>
            <div class="text-center">
                <img style="max-width: 100%; max-height: 200px;" :src="product.foto" :alt="product.nombre" />
            </div>
        </template>
        <v-file-input
            v-model="form.foto"
            label="Foto"
            accept="image/*"
            outlined
            class="mt-4"
        />
        <div class="d-flex justify-end mt-4" style="gap: 16px;">
            <v-btn class="flex-grow-1 flex-shrink-1" @click.stop="onGoBack" color="secondary">Atrás</v-btn>
            <v-btn class="flex-grow-1 flex-shrink-1" type="submit" color="primary">Finalizar</v-btn>
        </div>
    </v-form>
</template>
<script>
export default {
    name: 'PhotoEditForm',
    emits: ['finish'],
    props: {
        product: { type: Object, },
        onGoBack: { type: Function, required: true, },
    },
    data: () => ({
        form: {
            foto: null,
        },
    }),
    methods: {
        submit() {
            const update = this.form.foto ? { ...this.form } : null;
            this.form.foto = null;
            this.$emit('finish', update);
        },
    },
}
</script>