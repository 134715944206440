<template>
    <Fragment>
        <v-dialog v-model="isOpen" persistent fullscreen>
            <div style="background-color: #FFFFFF; padding: 0 1em 1em; position: relative; height: inherit;">
                <v-container style="max-height: 90vh;  position: relative;">
                    <div class="py-4"
                        style="width: 100%; background-color: inherit; position: sticky; top: 0; z-index: 10;">
                        <v-btn
                            style="min-width: unset !important; min-height: unset !important; width: 32px !important; height: 32px !important;"
                            class="px-4 py-4" @click.stop="cerrarModal" text rounded>
                            <v-icon>mdi-window-close</v-icon>
                        </v-btn>
                    </div>
                    <h3 class="mb-2 pb-4">Agregar direcciones</h3>
                    <v-card>
                    <v-row>
                        <v-col md="4" cols="4" class="mt-4 pl-10 align-center justify-space-around">
                            <v-text-field 
                                ref="direccion" 
                                label="Ingrese la dirección" 
                                v-model="direccion" 
                                outlined
                                @input="$v.direccion.$touch()" 
                                :error-messages="direccionErrors">
                            </v-text-field>
                        </v-col>
                        
                        <v-col md="4" cols="4" class="mt-6 align-center justify-space-around">
                            <v-btn @click="manejarGuardar">Agregar direccion</v-btn>
                        </v-col>
                        <v-col md="12" cols="12">
                            <EmptyComponent :empty="getDirecciones.length === 0" text="No hay direcciones agregadas">
                                <div style="max-height: 500px; overflow-y: auto;">
                                    <listado 
                                        @close="cerrarModal" 
                                        :oferta="oferta" 
                                    />
                                </div>
                            </EmptyComponent>
                        </v-col>
                    </v-row>
                </v-card>
                </v-container>
            </div>
        </v-dialog>
            <ConfirmationModalComponent
            :isOpen="modalConfirmacionActualizar" 
            description="¿Desea continuar con la acción?"
            @confirm="sumarDireccion"
            @cancel="modalConfirmacionActualizar = false"
        />
    </Fragment>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";
import { Validator } from '@/utils/form-validation.js';
import { Fragment } from 'vue-fragment';
import listado from '@/views/CMConvenio/components/cmConvenioDireccionesListado.vue'
import { ConfirmationModalComponent, EmptyComponent } from "@/components/utils";
import { 
    createLoadable, 
    toggleLoadable,
    setLoadableResponse,
} from '@/utils/loadable.js';

export default {
    name: 'CmDireccionModal',
    components: { Fragment, listado, EmptyComponent, ConfirmationModalComponent },
    props: {
        isOpen: { type: Boolean, },
        isLoading: { type: Boolean },
        oferta: { type: Object },
    },
    data: () => ({
        id: null,
        direccion: null,
        id_oferta_producto: null,
        modalConfirmacionActualizar: null,
        direccionGuardar: null,
        upsertAction: createLoadable(null),
    }),
    validations: {
        direccion: {
            required
        }
    },
    methods: {
        ...mapActions('cmConvenioStore', ['agregarOfertaDireccion','agregarDireccion','resetDireccionEdit']),

        manejarGuardar() {
            if (this.$v.$invalid) {
                this.$v.$touch()
                return
            }
            this.modalConfirmacionActualizar = true;
        },

        async sumarDireccion() {
            toggleLoadable(this.upsertAction);
            const resultados = await this.agregarOfertaDireccion([{
                id: this.id,
                direccion: this.direccion,
                id_oferta_producto: this.oferta.id,
            }]);
            setLoadableResponse(this.upsertAction, resultados, { showAlertOnSuccess: true, skipOnSuccess: true });

            const idDireccion = resultados.data[0].id;

            this.agregarDireccion({
                id: idDireccion,
                direccion: this.direccion,
                id_oferta_producto: this.oferta.id,
            })
        
            this.limpiarFormulario();
            this.modalConfirmacionActualizar = false;

        },
        cerrarModal(){
            this.$emit('close')
            this.resetDireccionEdit();
            this.limpiarFormulario();

        },
        limpiarFormulario() {
            this.id = null
            this.direccion = null
            this.id_oferta_producto = null
            this.$v.$reset()
        },
    },
    watch: {
        getDireccionEdit(valor) {
            if (valor) {
                this.id = valor.id
                this.direccion = valor.direccion
                this.id_oferta_producto = valor.id_oferta_producto
                this.$refs.direccion.focus()
            }
        }
    },
    computed: {
        direccionErrors() {
            return new Validator(this.$v.direccion).detect().getResult();
        },
        ...mapGetters('cmConvenioStore', ['getDireccionEdit', 'getDirecciones']),
    },
}
</script>