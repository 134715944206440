<template>
    <v-dialog v-model="isOpen" persistent width="800">
        <div class="upsert-product-modal pb-8 pt-16 px-8 position-relative" style="position: relative;">
            <v-btn 
                @click="close" 
                icon 
                color="secondary" 
                style="position: absolute; top: 16px; left: 16px;"
            >
                <v-icon>mdi-window-close</v-icon>
            </v-btn>
            <div>
            <h3 class="mt-10 mb-2">Especificaciones técnicas</h3>
                <v-data-table :headers="especificacionesHeaders" :items="items" hide-default-footer>
                    <template v-slot:item.categoria_c="{ item }">
                        {{ `${item.categoria_c_ini} - ${item.categoria_c_fin}` }}
                    </template>
                    <template v-slot:item.categoria_b="{ item }">
                        {{ `${item.categoria_b_ini} - ${item.categoria_b_fin}` }}
                    </template>
                </v-data-table>
            </div>
            <div class="text-center mt-10">
                    <v-btn class="primary" @click.stop="close">Cerrar</v-btn>
                </div>
        </div>
    </v-dialog>
</template>
<script>
export default {
    name: 'ProductDetailsModal',
    props: { 
        isOpen: { type: Boolean, },
        subgrupo: { type: Object },
    },
    emits: ['close'],
    methods: {
        close() {
            this.$emit('close');
        },
    },
    data: () => ({
        especificacionesHeaders: [
            { align: 'center', sortable: false, text: 'Categoría C', value: 'categoria_c' },
            { align: 'center', sortable: false, text: 'Categoría B', value: 'categoria_b' },
            { align: 'center', sortable: false, text: 'Categoría A', value: 'categoria_a' },
        ],
    }),
    computed: {
        items() {
            return this.$props.subgrupo ? [this.$props.subgrupo] : [];
        },
    },
}
</script>