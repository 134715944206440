<template>
  <section>
    <v-card class="px-4 py-4">
      <v-card-title class="px-0 py-0 mb-4 justify-end">
        <v-btn dark color="secondary" @click="familiasDialog = true">Agregar</v-btn>
      </v-card-title>
      <v-card-text class="px-0 py-0 mb-8">
        <data-table-component
          v-models:select="perPage"
          v-models:pagina="page"
          :headers="headers"
          :items="items"
          @paginar="paginate"
          :total_registros="totalRows"
        >
          <template v-slot:item.codigo_convenio="{ item }">
            {{ item.codigo_convenio ? item.codigo_convenio : '-' }}
          </template>
          <template v-slot:item.fecha_convenio="{ item }">
            {{ item?.fecha_convenio ? moment(item?.fecha_convenio).format("DD/MM/YYYY") : '-'   }}
          </template>
          <template v-slot:[`item.activo`]="{ item }">
            <v-chip :class="item.activo ? 'secondary ' : ''" :style="item.activo ? 'color:white !important' : ''">
              {{ item.activo ? "Activo" : "Inactivo" }}
            </v-chip>
          </template>
          <template v-slot:item.icono="{ item }">
            <v-img class="my-2" max-height="70" max-width="70" :src="item.icono" v-if="item.icono && item.icono !== '0'" />
            <v-icon v-else class="my-2">mdi mdi-image</v-icon>
          </template>
          <template v-slot:[`item.acciones`]="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                    @click="editarFamilia(item)"
                    v-bind="attrs"
                    v-on="on"
                    large
                    color="primary"
                >
                  mdi-pencil
                </v-icon>
              </template>

              <span>Editar</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                    @click="(deleteDialog = true), (familiaSeleccionada = item.id)"
                    v-bind="attrs"
                    v-on="on"
                    large
                    color="primary"
                >
                  mdi-delete
                </v-icon>
              </template>

              <span>Eliminar</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    @click="(modalEstado = true), (familiaSeleccionada = item.id), (estado = item.activo)"

                    :disabled="
                            !haveRoles([
                              'ROLE_DIRECCION_COMPRAS',
                              'ROLE_TECNICO_DIRECCION_COMPRAS',
                              'ROLE_CM_FAMILIAS_ACTIVO',
                            ])
                          "
                >
                  <v-icon>{{
                      item.activo
                          ? "mdi-lock-outline"
                          : "mdi-lock-open-outline"
                    }}
                  </v-icon>
                </v-btn>
              </template>
              <span> Deshabilitar </span>
            </v-tooltip>

            <v-tooltip v-if="item.circular" top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-on="on" v-bind="attrs" icon @click.stop="confirmarEliminadoCircular(item)">
                  <v-icon>mdi-file-remove</v-icon>
                </v-btn>
              </template>
              <span>Eliminar circular</span>
            </v-tooltip>

            <v-tooltip v-if="item.circular" top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-on="on" v-bind="attrs" icon @click.stop="visualizarCircular(item)">
                  <v-icon>mdi-file-eye</v-icon>
                </v-btn>
              </template>
              <span>Visualizar circular</span>
            </v-tooltip>
          </template>
        </data-table-component>
      </v-card-text>
    </v-card>
    <v-dialog persistent max-width="1000" v-model="familiasDialog">
      <v-card>
        <v-card-title class="px-4 py-4" style="gap: 8px;">
          <v-btn @click="clearInfo" icon color="secondary">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <p class="text-h6 secondary--text mb-0 font-weight-bold text-uppercase">
            {{ editable ? "Editar" : "Agregar" }} familia
          </p>
        </v-card-title>
        <v-divider />
        <v-card-text class="px-4 py-4">
          <div class="d-flex flex-column flex-md-row justify-center align-center" style="gap: 16px;">
            <v-text-field
              v-model="familiaObj.codigo_convenio"
              label="Codigo convenio*"
              class="flex-grow-1 flex-shrink-1"
              outlined
            />
            <v-menu transition="scale-transition" min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="familiaObj.fecha_convenio"
                    outlined
                    label="Fecha convenio *"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="familiaObj.fecha_convenio"
                  locale="es"
                  no-title
                  color="primary"
                  @input="menuRecepcion = false"
                ></v-date-picker>
              </v-menu>
          </div>
          
          <div class="d-flex flex-column flex-md-row justify-center align-center" style="gap: 16px;">
            <v-text-field
              v-model="familiaObj.codigo"
              style="width: 100%"
              label="Codigo *"
              class="flex-grow-1 flex-shrink-1"
              outlined
              @input="$v.familiaObj.codigo.$touch()"
              :error-messages="codigoError"
            />
            <v-text-field
              v-model="familiaObj.nombre"
              style="width: 100%"
              class="flex-grow-1 flex-shrink-1"
              label="Nombre *"
              outlined
              @input="$v.familiaObj.nombre.$touch()"
              :error-messages="nombreError"
            />
          </div>
            
          <div class="d-flex flex-column flex-md-row justify-center align-center" style="gap: 16px;">
            <v-file-input
              v-model="familiaObj.circular"
              style="width: 100%"
              label="Circular"
              class="flex-grow-1 flex-shrink-1"
              placeholder="Seleccione un PDF"
              accept="application/pdf"
              outlined
            />

            <v-file-input
              v-model="familiaObj.icono"
              style="width: 100%"
              class="flex-grow-1 flex-shrink-1"
              label="Icono"
              @input="$v.familiaObj.icono.$touch()"
              accept="image/*"
              outlined
            />
          </div>

          <img 
            v-if="imgExistente" 
            class="my-2 d-block mx-auto" 
            max-height="50" 
            max-width="50" 
            :src="imgExistente" 
            style="max-height: 150px;" 
          />
        </v-card-text>
        <v-card-actions class="px-4 py-4">
          <v-btn
              color="secondary"
              class="flex-grow-1 flex-shrink-1"
              large
              @click="clearInfo()"
          >
            Cancelar
          </v-btn>
          <v-btn
              color="primary"
              class="flex-grow-1 flex-shrink-1"
              large
              @click="guardarFamilia"
          >
            Agregar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <dialogoConfirmacion
        :show="modalEstado"
        btnConfirmar="Aceptar"
        :title="`¿Está seguro de ${
        familiaSeleccionada?.activo ? 'deshabilitar' : 'habilitar'
      } la familia?`"
        message="Esta acción afectará la estructura de productos del catálogo"
        @close="modalEstado = false"
        @confirm="deshabilitar"
    />

    <dialogoConfirmacion
        :show="deleteDialog"
        title="¿Deseas remover esta familia?"
        message="De confirmarse la siguiente acción, los cambios no serán reversibles."
        btnConfirmar="Aceptar"
        @close="(deleteDialog = false), (familiaSeleccionada = null)"
        @confirm="eliminarFamilia()"
    />

    <ConfirmationModalComponent 
      :is-open="confirmacionEliminacionCircular"
      :is-loading="eliminadoCircular.isLoading"
      description="¿Desea eliminar la circular de la familia seleccionada?"
      @cancel="confirmacionEliminacionCircular = false"
      @confirm="eliminarCircular"
    />

    <PdfModal
        :isOpen="visualizacionCircularAbierta" 
        :source-loadable="visualizacionCircular" 
        @on-visibility-change="manejarVisibilidadVisualizacionCircular"
        :filename="`circular${familiaSeleccionada ? '-' + familiaSeleccionada.nombre : ''}`"
      />
  </section>
</template>
<script>
import { createLoadable, isApiErrorResponse, isResponseSuccesful, setLoadableResponse, toggleLoadable } from "@/utils/loadable";
import dialogoConfirmacion from "../../../components/ConfirmationDialogComponent.vue";
import { ConfirmationModalComponent } from "@/components/utils";
import DataTableComponent from "../../../components/DataTableComponent.vue";
import {helpers, required} from "vuelidate/lib/validators";
import {Validator} from '@/utils/form-validation.js';
import { convertToFormData } from "@/utils/data";
import { Observable } from '@/utils/observable.js';
import PdfModal from '@/components/PdfModal.vue';
import moment from "moment";

const txtField = helpers.regex(
    "txtArea",
    /^[A-Za-z0-9-&.,:;()À-ÿ\u00f1\u00d1 ]*$/
);

export default {
  name: "FamiliasComponent",
  components: {
    DataTableComponent,
    dialogoConfirmacion,
    ConfirmationModalComponent,
    PdfModal,
  },
  data: () => ({
    headers: [
    {
        text: "Icono",
        value: "icono",
        align: "center",
      },
      {
        text: "Código familia",
        value: "codigo",
        align: "center",
      },
      {
        text: "Código convenio",
        value: "codigo_convenio",
        align: "center",
      },
      {
        text: "Fecha convenio",
        value: "fecha_convenio",
        align: "center",
      },
      {
        text: "Nombre",
        value: "nombre",
        align: "center",
      },
      {
        text: "Estado",
        value: "activo",
        align: "center",
      },
      {
        text: "Acciones",
        value: "acciones",
        align: "center",
        sortable: false,
      },
    ],
    items: [],
    totalRows: 0,
    page: 1,
    perPage: 10,
    familiasDialog: false,
    tabMenu: null,
    menuRecepcion: null,
    familiaObj: {
      id: null,
      codigo: null,
      nombre: null,
      icono: null,
      circular: null,
      codigo_convenio: null,
      fecha_convenio: null,
    },
    imgExistente : null,
    deleteDialog: false,
    estado: false,
    modalEstado: false,
    familiaSeleccionada: null,
    editable: false,
    // Circulares
    confirmacionEliminacionCircular: null,
    eliminadoCircular: createLoadable(null),
    visualizacionCircular: createLoadable(null),
    visualizacionCircularAbierta: false,
  }),
  validations: {
    familiaObj: {
      codigo: {required, txtField},
      nombre: {required, txtField},
      // codigo_convenio: {required,txtField},
      // fecha_convenio: {required,txtField},
    },
  },
  computed: {
    nombreError() {
      return new Validator(this.$v.familiaObj.nombre).detect().getResult();
    },
    iconoErrors() {
      return new Validator(this.$v.familiaObj.icono).detect().getResult();
    },
    codigoError() {
      return new Validator(this.$v.familiaObj.codigo).detect().getResult();
    },
    // codigoConvenioError() {
    //   return new Validator(this.$v.familiaObj.codigo_convenio).detect().getResult();
    // },
    // fechaConvenioError() {
    //   return new Validator(this.$v.familiaObj.fecha_convenio).detect().getResult();
    // },
  },
  methods: {
    async deshabilitar() {

      this.familiaObj.id = this.familiaSeleccionada;
      this.familiaObj.activo = !this.estado;

      const response = await this.services.CmFamilias.putFamiliasEstado(
          this.familiaObj.id,
          this.familiaObj
      ).catch(() => {

      });
      if (response?.status === 200) {
        this.temporalAlert({
          show: true,
          type: "success",
          message: response?.data.message,
        });
      }
      this.modalEstado = false;
      this.familiaSeleccionada = null;
      this.getFamilias();

    },
    async getFamilias() {
      const {status, data, headers} =
          await this.services.CmFamilias.obtenerFamilias({
            page: this.page,
            per_page: this.perPage,
          });

      if (status == 200) {

        this.items = data.data;
        this.totalRows = Number(headers.total_rows);
      }
    },
    paginate(params) {
      const {cantidad_por_pagina, pagina} = params;
      this.perPage = cantidad_por_pagina;
      this.page = pagina;

      this.getFamilias();
    },
    clearInfo() {
      this.familiaObj.id = null;
      this.familiaObj.codigo = null;
      this.familiaObj.nombre = null;
      this.familiaObj.circular = null;
      this.familiaObj.icono = null;
      this.familiaObj.codigo_convenio = null;
      this.familiaObj.fecha_convenio = null;
      this.imgExistente = null;
      this.familiasDialog = false;
      this.editable = false;
      this.$v.$reset();
    },
    async guardarFamilia() {

      this.$v.$touch();

      if (this.$v.$invalid) return;
      const formData = convertToFormData(this.familiaObj);

      const response = await this.services.CmFamilias.upsertFamilia(formData, this.familiaObj.id);

      if (response.status == 201 || response.status == 200) {
        this.temporalAlert({
          show: true,
          message: `Familia ${this.editable ? "editada" : "guardada"} con éxito`,
          type: "success",
        });
        this.clearInfo();
        this.getFamilias();
        Observable.emit("refresh-metodos-cm")
      }
    },
    editarFamilia(item) {
      this.familiaObj.id = item.id;
      this.familiaObj.codigo = item.codigo;
      this.familiaObj.nombre = item.nombre;
      this.familiaObj.codigo_convenio = item.codigo_convenio;
      this.familiaObj.fecha_convenio =  item.fecha_convenio ? moment(item.fecha_convenio).format('YYYY-MM-DD') : '';
      this.imgExistente = item.icono;
      this.editable = true;
      this.familiasDialog = true;
    },

    async eliminarFamilia() {
      const {status} = await this.services.CmFamilias.deleteFamilias(
          this.familiaSeleccionada
      );
      if (status == 200) {
        this.temporalAlert({
          show: true,
          message: `Familia eliminada con éxito`,
          type: "success",
        });
        this.familiaSeleccionada = null;
        this.deleteDialog = false;
        this.getFamilias();
      }
    },
    // Circulares
    confirmarEliminadoCircular(familia) {
      this.familiaSeleccionada = { ...familia };
      this.confirmacionEliminacionCircular = true;
    },
    async eliminarCircular() {
      toggleLoadable(this.eliminadoCircular);
      const { data } = await this.services.CmFamilias.eliminarCircular(this.familiaSeleccionada.id);
      this.confirmacionEliminacionCircular = false;
      setLoadableResponse(this.eliminadoCircular, data, { showAlertOnSuccess: true, skipOnSuccess: true });

      if (isResponseSuccesful(data) || ['FAMILIA_NO_ENCONTRADA', 'FAMILIA_SIN_CIRCULAR'].includes(data.error.code)) {
        this.getFamilias();
      }
    },
    async visualizarCircular(familia) {
      this.familiaSeleccionada = { ...familia };
      this.visualizacionCircularAbierta = true;
      toggleLoadable(this.visualizacionCircular);
      const { data } = await this.services.CmFamilias.visualizarCircular(familia.id);
      setLoadableResponse(this.visualizacionCircular, data, { isFile: true });

      if (isApiErrorResponse(data) && ['FAMILIA_NO_ENCONTRADA', 'FAMILIA_SIN_CIRCULAR'].includes(data.error.code)) {
        this.getFamilias();
      }
    },
    manejarVisibilidadVisualizacionCircular(visible) {
      this.visualizacionCircularAbierta = visible;
    },
  },
  created() {
    this.getFamilias();
  },
};
</script>
