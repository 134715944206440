<template>
    <v-dialog v-model="isOpen" persistent width="800">
        <div class="upsert-product-modal pb-8 pt-16 px-8 position-relative" style="position: relative;">
            <v-btn 
                @click="close" 
                icon 
                color="secondary" 
                style="position: absolute; top: 16px; left: 16px;"
            >
                <v-icon>mdi-window-close</v-icon>
            </v-btn>
            <div v-if="product">
                <h3 class="mb-2">Generalidades</h3>
                <v-data-table :headers="generalidadesHeaders" :items="items" hide-default-footer>
                    <template v-slot:item.obs="{ item }">
                        {{ `${item.CmSubGrupo.Ob.codigo}-${item.CmSubGrupo.Ob.nombre}` }}
                    </template>
                    <template v-slot:item.foto="{ item }">
                        <v-img height="70" width="70" :src="item.foto" class="my-4" />
                    </template>
                </v-data-table>
                <div class="text-center mt-10">
                    <v-btn class="primary" @click.stop="close">Cerrar</v-btn>
                </div>
            </div>
        </div>
    </v-dialog>
</template>
<script>
export default {
    name: 'ProductDetailsModal',
    props: { 
        isOpen: { type: Boolean, },
        product: { type: Object },
    },
    emits: ['close'],
    methods: {
        close() {
            this.$emit('close');
        },
    },
    data: () => ({
        generalidadesHeaders: [
            { align: 'center', sortable: false, text: 'Foto', value: 'foto' },
            { align: 'center', sortable: false, text: 'Obs', value: 'obs' },
            { align: 'center', sortable: false, text: 'Nombre', value: 'nombre' },
            { align: 'center', sortable: false, text: 'Numero', value: 'numero' },
            { align: 'center', sortable: false, text: 'Descripción', value: 'descripcion' },
            { align: 'center', sortable: false, text: 'Precio promedio', value: 'precio_promedio' },
            { align: 'center', sortable: false, text: 'Disponibilidad', value: 'disponibilidad' },
            { align: 'center', sortable: false, text: 'Ventas perdidas', value: 'ventas_perdidas' },
        ],
        especificacionesHeaders: [
            { align: 'center', sortable: false, text: 'Categoría C', value: 'categoria_c' },
            { align: 'center', sortable: false, text: 'Categoría B', value: 'categoria_b' },
            { align: 'center', sortable: false, text: 'Categoría A', value: 'categoria_a' },
        ],
    }),
    computed: {
        items() {
            return this.$props.product ? [this.$props.product] : [];
        },
    },
}
</script>