<template>
    <Fragment>
        <v-dialog v-model="isOpen" persistent fullscreen>
            <div style="background-color: #FFFFFF; padding: 0 1em 1em; position: relative; height: inherit;">
                <v-container style="max-height: 100vh;  position: relative;">
                    <div class="py-4"
                        style="width: 100%; background-color: inherit; position: sticky; top: 0; z-index: 10;">
                        <v-btn
                            style="min-width: unset !important; min-height: unset !important; width: 32px !important; height: 32px !important;"
                            class="px-4 py-4" @click.stop="cerrarModal" text rounded>
                            <v-icon>mdi-window-close</v-icon>
                        </v-btn>
                    </div>
                    <h3 class="mb-2 pb-4">Agregar regiones</h3>
                    <v-card>
                        <v-row>
                            <v-col md="12" cols="12" class="d-flex flex-column align-center">
                                <v-row>
                                    <v-col md="12" cols="12" class="d-flex flex-row align-center">
                                        <h3 class="mr-2">Tiempo en horas</h3>
                                        <v-switch color="secondary" inset v-model="conversorDiasAHoras"></v-switch>
                                        <h3 class="mr-2">Tiempo en días</h3>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-row>
                                <v-col md="4" cols="12" class="pl-10">
                                    <v-autocomplete return-object v-model="regionConvenio.CMRegion"
                                        label="Seleccione la región" :items="regiones" outlined
                                        @input="$v.regionConvenio.CMRegion.$touch()" :error-messages="regionErrors"
                                        item-text="nombre"></v-autocomplete>
                                </v-col>

                                <v-col md="2" cols="12">
                                    <v-text-field outlined label="Tiempo de respuesta"
                                        v-model="regionConvenio.tiempo_respuesta"
                                        @input="$v.regionConvenio.tiempo_respuesta.$touch()"
                                        :error-messages="tiempoRespuestaError"></v-text-field>
                                </v-col>

                                <v-col md="3" cols="12">
                                    <v-text-field label="Tiempo de respuesta en emergencia" outlined
                                        v-model="regionConvenio.tiempo_respuesta_eme"
                                        @input="$v.regionConvenio.tiempo_respuesta_eme.$touch()"
                                        :error-messages="tiempoRespuestaEmeError"></v-text-field>
                                </v-col>

                                <v-col md="2" cols="4" class="align-center justify-space-around">
                                    <v-btn color="primary" @click="manejarAgregar">Agregar</v-btn>
                                </v-col>
                            </v-row>

                            <v-col md="12" cols="12">
                                <EmptyComponent :empty="getListadoRegiones.length === 0"
                                    text="No hay direcciones agregadas">
                                    <div style="max-height: 500px;">
                                        <listado @close="cerrarModal" :oferta="oferta" />
                                    </div>
                                </EmptyComponent>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-container>
            </div>
        </v-dialog>
        <ConfirmationModalComponent
            :isOpen="modalConfirmacionActualizar" 
            description="¿Desea continuar con la acción?"
            @confirm="agregar"
            @cancel="modalConfirmacionActualizar = false"
        />
    </Fragment>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { Fragment } from 'vue-fragment';
import listado from '@/views/CMConvenio/components/cmConvenioRegionesListado.vue'
import { required, numeric } from "vuelidate/lib/validators";
import { Validator } from '@/utils/form-validation.js';
import {
    createLoadable,
    toggleLoadable,
    setLoadableResponse,
} from '@/utils/loadable.js';
import { ConfirmationModalComponent, EmptyComponent } from "@/components/utils";

export default {
    name: 'cmRegionModal',
    components: {
    listado,
    Fragment,
    EmptyComponent,
    ConfirmationModalComponent
},
    props: {
        isOpen: { type: Boolean, },
        isLoading: { type: Boolean },
        oferta: { type: Object },
        actions: {
            type: Boolean,
            default: true
        }
    },
    data: () => ({
        e1: 1,
        listado: [],
        regiones: [],
        modalConfirmacionActualizar: false,
        regionConvenio: {
            id: null,
            CMRegion: null,
            id_oferta_producto: null,
            tiempo_respuesta: null,
            tiempo_respuesta_eme: null,
        },
        conversorDiasAHoras: false,
        upsertAction: createLoadable(null),

    }),
    validations: {
        regionConvenio: {
            CMRegion: { required },
            tiempo_respuesta: { required, numeric },
            tiempo_respuesta_eme: { required, numeric },
        },
    },
    watch: {
        getRegionEdit(valor) {
            if (valor) {
                this.regionConvenio = valor
            }
        }
    },
    computed: {
        ...mapGetters("cmConvenioStore", ["getListadoRegiones", "getRegionEdit"]),
        tiempoRespuestaError() {
            return new Validator(this.$v.regionConvenio.tiempo_respuesta).detect().getResult();
        },
        tiempoRespuestaEmeError() {
            return new Validator(this.$v.regionConvenio.tiempo_respuesta_eme).detect().getResult();
        },
        regionErrors() {
            return new Validator(this.$v.regionConvenio.CMRegion).detect().getResult();
        }
    },

    methods: {
        ...mapActions("cmConvenioStore", ["agregarRegion","agregarOfertaRegion","resetRegionEdit"]),

        manejarAgregar(){
            if (this.$v.$invalid) {
                this.$v.$touch()
                return
            }

            const regiones = this.getListadoRegiones;
            const regionExiste = regiones.some(item => item.CMRegion.id === this.regionConvenio.CMRegion.id);

            if (regionExiste) {
                this.pushAppMessage({
                    type: 'error',
                    message: 'Esta región ya se encuentra añadida',
                });
                return;
            }
            this.modalConfirmacionActualizar = true;
        },
        async agregar() {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                this.convertirDiasAHoras();
                this.listado.push(this.regionConvenio)

                toggleLoadable(this.upsertAction);
                const resultados = await this.agregarOfertaRegion([{
                    id: this.regionConvenio.id,
                    id_region: this.regionConvenio.CMRegion.id,
                    activo: true,
                    id_oferta_producto: this.oferta.id,
                    tiempo_respuesta: this.regionConvenio.tiempo_respuesta,
                    tiempo_respuesta_eme: this.regionConvenio.tiempo_respuesta_eme,
                }]);
                setLoadableResponse(this.upsertAction, resultados, { showAlertOnSuccess: true, skipOnSuccess: true });

                const idRegion = resultados.data[0].id;
                
                this.agregarRegion({
                    ...this.regionConvenio,
                    id: idRegion,
                })
                this.limpiar()
                this.modalConfirmacionActualizar = false;

            }
        },
        limpiar() {
            this.regionConvenio = {
                id: null,
                region: null,
                id_oferta_producto: null,
                tiempo_respuesta: null,
                tiempo_respuesta_eme: null,
            }
            this.$v.$reset();
        },
        async cargarRegiones() {
            const { status, data } =
                await this.services.cmRegiones.listRegion({
                    pagination: false,
                });
            if (status === 200) {
                this.regiones = data.data;
            }
        },

        convertirDiasAHoras() {
            if (this.conversorDiasAHoras) {
                // Si el switch está activado, realiza la conversión de días a horas
                if (this.regionConvenio.tiempo_respuesta !== null) {
                    this.regionConvenio.tiempo_respuesta *= 24; // Convierte días a horas
                }
                if (this.regionConvenio.tiempo_respuesta_eme !== null) {
                    this.regionConvenio.tiempo_respuesta_eme *= 24; // Convierte días a horas
                }
            }
        },
        cerrarModal() {
            this.resetRegionEdit();
            this.limpiarFormulario();
            this.$emit('close')
        },
        limpiarFormulario() {
            this.regionConvenio = {},
            this.$v.$reset()
        },
    },
    async created() {
        await this.cargarRegiones();
    }
}
</script>