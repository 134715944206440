<template>
  <Fragment>
    <v-dialog v-model="isOpen" persistent width="900">
      <div
        class="upsert-product-modal pb-8 pt-4 px-8 position-relative"
        style="position: relative"
      >
        <v-card-title class="d-flex align-center ml-2">
          <v-icon class="mr-2">mdi-clipboard-account-outline</v-icon>
          <span class="font-weight-bold mr-2">Cargar ofertas</span>
          <v-btn
            dark
            color="primary"
            class="cm-producto-btn ml-auto"
            prepend-icon="mdi-download"
            @click="DescargarArchivoProveedor()"
          >
            <v-icon class="mr-2">mdi-download-box</v-icon>
            Formato ID Proveedor
          </v-btn>
          <v-btn
            dark
            color="primary"
            class="cm-producto-btn ml-auto"
            prepend-icon="mdi-download"
            @click="DescargarArchivoSinProveedor()"
          >
            <v-icon class="mr-2">mdi-download-box</v-icon>
            Formato sin Proveedor
          </v-btn>
        </v-card-title>
        <v-row>
          <v-col cols="12" class="mt-4 px-0 py-0 flex-shrink-1 flex-grow-1">
            <ProgresiveLoadAutocomplete
              v-model="form.id_proveedor"
              placeholder="Seleccione un proveedor"
              label="Proveedor"
              outlined
              :items="proveedores.data"
              :loading="proveedores.isLoading"
              :pageable="proveedores"
              item-value="id"
              item-text="nombre_comercial"
              :transform-item="transformarProveedor"
              @search="buscarProveedor"
              @load-more="cargarMasProveedores"
              clearable
            />
          </v-col>

          <v-col cols="12" class="mt-4 px-0 py-0 flex-shrink-1 flex-grow-1">
            <v-file-input
              v-model="form.adjunto"
              outlined
              prepend-icon=""
              placeholder="Adjuntar archivo de excel"
              label="Adjunto *"
              @input="$v.form.adjunto.$touch()"
              @blur="$v.form.adjunto.$touch()"
              :error-messages="adjuntoErrors"
              accept=".xlsx"
              prepend-inner-icon="mdi-paperclip"
            />
          </v-col>
        </v-row>

        <div class="d-flex justify-end mt-4">
          <v-btn
            class="secondary flex-grow-1 flex-shrink-1"
            @click="cerrarModal"
            >Cerrar</v-btn
          >
          <v-btn
            class="flex-grow-1 flex-shrink-1 ml-4"
            @click="confirmarCargarOfertas"
            color="primary"
            >Guardar</v-btn
          >
        </div>
      </div>
    </v-dialog>
    <ConfirmationModalComponent
      :isOpen="modalCargarOferta"
      description="¿Desea continuar con la acción?"
      @confirm="confirmarGuardado"
      @cancel="modalCargarOferta = false"
      :isLoading="cargarOferta.isLoading"
    />
  </Fragment>
</template>

<script>
import ConfirmationModalComponent from "@/components/utils/ConfirmationModalComponent.vue";

import {
  toggleLoadable,
  setLoadableResponse,
  isResponseSuccesful,
  createLoadable,
} from "@/utils/loadable.js";
import { Fragment } from "vue-fragment";
import excel4node from "excel4node";
import ProgresiveLoadAutocomplete from "@/components/utils/ProgresiveLoadAutocomplete.vue";
import { Validator } from "@/utils/form-validation.js";
import { required } from "vuelidate/lib/validators";
import { convertToFormData, removePropsFromObjectIfNil } from "@/utils/data";

export default {
  name: "CmCargarOfertasModal",
  components: {
    ConfirmationModalComponent,
    ProgresiveLoadAutocomplete,
    Fragment,
  },
  validations() {
    return {
      form: {
        adjunto: { required },
      },
    };
  },
  data: () => ({
    cargarOferta: createLoadable(null),
    filtroProveedor: "",
    form: {
      adjunto: null,
    },
    modalCargarOferta: false,
    paginacionProveedores: {
      pagina: 1,
      registrosPorPagina: 30,
    },
  }),
  props: {
    isOpen: { type: Boolean },
    proveedores: { type: Object },
  },
  emits: ["close", "on-save"],
  methods: {
    transformarProveedor(proveedor) {
      return {
        ...proveedor,
        nombre_comercial: `${proveedor?.id} - ${proveedor.nombre_comercial}`,
      };
    },
    async buscarProveedor(termino) {
      this.$emit("buscarProveedor", termino);
    },
    async cargarMasProveedores() {
      this.$emit("cargarMasProveedores");
    },
    async DescargarArchivoSinProveedor() {
      const workbook = new excel4node.Workbook();
      const sheet = workbook.addWorksheet("Formato");
      const columns = [
        { columna: 1, nombre: "sku" },
        { columna: 2, nombre: "precio" },
        { columna: 3, nombre: "unidad_medida" },
        { columna: 4, nombre: "disponibilidad" },
        { columna: 5, nombre: "marca" },
        { columna: 6, nombre: "tiempo_respuesta_central" },
        { columna: 7, nombre: "tiempo_respuesta_eme_central" },
        { columna: 8, nombre: "tiempo_respuesta_central_2" },
        { columna: 9, nombre: "tiempo_respuesta_eme_central_2" },
        { columna: 10, nombre: "tiempo_respuesta_central_3" },
        { columna: 11, nombre: "tiempo_respuesta_eme_central_3" },
        { columna: 12, nombre: "tiempo_respuesta_metropolitana" },
        { columna: 13, nombre: "tiempo_respuesta_eme_metropolitana" },
        { columna: 14, nombre: "tiempo_respuesta_occidental" },
        { columna: 15, nombre: "tiempo_respuesta_eme_occidental" },
        { columna: 16, nombre: "tiempo_respuesta_occidental_2" },
        { columna: 17, nombre: "tiempo_respuesta_eme_occidental_2" },
        { columna: 18, nombre: "tiempo_respuesta_oriental" },
        { columna: 19, nombre: "tiempo_respuesta_eme_oriental" },
        { columna: 20, nombre: "tiempo_respuesta_oriental_2" },
        { columna: 21, nombre: "tiempo_respuesta_eme_oriental_2" },
        { columna: 22, nombre: "tiempo_respuesta_oriental_3" },
        { columna: 23, nombre: "tiempo_respuesta_eme_oriental_3" },
        { columna: 24, nombre: "tiempo_respuesta_nivel_nacional" },
        { columna: 25, nombre: "tiempo_respuesta_eme_nivel_nacional" },
      ];

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < columns.length; i++) {
        sheet.cell(1, columns[i].columna).string(columns[i].nombre);
      }

      const data = await workbook.writeToBuffer();
      const url = URL.createObjectURL(
        new Blob([data], { type: "application/vnd.ms-excel" })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "formato-oferta-sin-proveedor.xlsx");
      document.body.appendChild(link);
      link.click();
    },
    async DescargarArchivoProveedor() {
      const workbook = new excel4node.Workbook();
      const sheet = workbook.addWorksheet("Formato");
      const columns = [
        { columna: 1, nombre: "id_proveedor" },
        { columna: 2, nombre: "sku" },
        { columna: 3, nombre: "precio" },
        { columna: 4, nombre: "unidad_medida" },
        { columna: 5, nombre: "disponibilidad" },
        { columna: 6, nombre: "marca" },
        { columna: 7, nombre: "tiempo_respuesta_central" },
        { columna: 8, nombre: "tiempo_respuesta_eme_central" },
        { columna: 9, nombre: "tiempo_respuesta_central_2" },
        { columna: 10, nombre: "tiempo_respuesta_eme_central_2" },
        { columna: 11, nombre: "tiempo_respuesta_central_3" },
        { columna: 12, nombre: "tiempo_respuesta_eme_central_3" },
        { columna: 13, nombre: "tiempo_respuesta_metropolitana" },
        { columna: 14, nombre: "tiempo_respuesta_eme_metropolitana" },
        { columna: 15, nombre: "tiempo_respuesta_occidental" },
        { columna: 16, nombre: "tiempo_respuesta_eme_occidental" },
        { columna: 17, nombre: "tiempo_respuesta_occidental_2" },
        { columna: 18, nombre: "tiempo_respuesta_eme_occidental_2" },
        { columna: 19, nombre: "tiempo_respuesta_oriental" },
        { columna: 20, nombre: "tiempo_respuesta_eme_oriental" },
        { columna: 21, nombre: "tiempo_respuesta_oriental_2" },
        { columna: 22, nombre: "tiempo_respuesta_eme_oriental_2" },
        { columna: 23, nombre: "tiempo_respuesta_oriental_3" },
        { columna: 24, nombre: "tiempo_respuesta_eme_oriental_3" },
        { columna: 25, nombre: "tiempo_respuesta_nivel_nacional" },
        { columna: 26, nombre: "tiempo_respuesta_eme_nivel_nacional" },
      ];

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < columns.length; i++) {
        sheet.cell(1, columns[i].columna).string(columns[i].nombre);
      }

      const data = await workbook.writeToBuffer();
      const url = URL.createObjectURL(
        new Blob([data], { type: "application/vnd.ms-excel" })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "formato-con-id-proveedor.xlsx");
      document.body.appendChild(link);
      link.click();
    },
    confirmarCargarOfertas() {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      this.modalCargarOferta = true;
    },
    async confirmarGuardado() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      const params = {
        id_proveedor: this.form.id_proveedor,
        adjunto: this.form.adjunto,
      };
      const formData = convertToFormData(
        removePropsFromObjectIfNil(params, Object.keys(params))
      );

      toggleLoadable(this.cargarOferta);
      const { data } = await this.services.cmProducto.cargarOfertasExcel(
        formData
      );
      setLoadableResponse(this.cargarOferta, data, {
        showAlertOnSuccess: true,
        skipOnSuccess: true,
      });
      this.modalCargarOferta = false;
      this.limpiarFormulario();
      this.cerrarModal();
    },
    limpiarFormulario() {
      this.$v.form.$reset();
      this.form = {};
    },
    cerrarModal() {
      this.limpiarFormulario();
      this.$emit("close");
    },
  },
  computed: {
    adjuntoErrors() {
      return new Validator(this.$v.form.adjunto).detect().getResult();
    },
  },
  created() {},
};
</script>
