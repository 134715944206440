<template>
        <v-dialog
            v-model="isOpen"
            persistent
            width="800"
        >
            <div class="upsert-product-modal pb-8 pt-16 px-8 position-relative" style="position: relative;">
                <v-btn 
                    @click="handleClose"
                    icon 
                    color="secondary" 
                    style="position: absolute; top: 16px; left: 16px;"
                >
                    <v-icon>mdi-window-close</v-icon>
                </v-btn>
                    <v-alert v-if="cargando" class="text-center">
                        <p class="pb-0 mb-4">Cargando producto...</p>
                        <v-progress-circular
                            :size="40"
                            :width="3"
                            :indeterminate="true"
                            color="primary"
                            class="mx-auto mt-4"
                        ></v-progress-circular>
                    </v-alert>
                    <v-stepper v-else v-model="steps" >
                        <v-stepper-header>
                            <v-stepper-step 
                                :complete="steps > stepsOrder.generalidades" 
                                :step="stepsOrder.generalidades"
                            >
                            Generalidades
                        </v-stepper-step>
                        <v-divider></v-divider>
                        <v-stepper-step 
                            v-if="product" 
                            :complete="steps > stepsOrder.fotografia" 
                            :step="stepsOrder.fotografia" 
                        >
                        Fotografía
                    </v-stepper-step>
                    <v-divider></v-divider>
                </v-stepper-header>
                    <v-stepper-content :step="stepsOrder.generalidades">
                        <GeneralidadesStep 
                        :editting="editting"
                        :onClose="handleClose" 
                        :product="product"
                        @moveNextStep="moveNextStep"
                        @finish="handleCreation" 
                        :isParentModalOpen="isOpen"
                        ref="generalidadesRef"
                        />
                    </v-stepper-content>
                    <v-stepper-content v-if="product" :step="stepsOrder.fotografia">
                        <PhotoEditForm 
                            :onGoBack="movePreviousStep" 
                            :product="product" 
                            @finish="finishSteps" 
                            :isParentModalOpen="isOpen"
                        />
                    </v-stepper-content>
                </v-stepper>
            </div>
            <ConfirmationModalComponent
                :isOpen="isConfirmModalOpen"
                :description="'¿Desea continuar con la acción?'"
                @confirm="save"
                @cancel="isConfirmModalOpen = false"
                :isLoading="producto.isLoading"
            />
        </v-dialog>
    </template>
<script>
import { convertToFormData } from '@/utils/data';
import GeneralidadesStep from './GeneralidadesForm.vue';
import PhotoEditForm from './PhotoEditForm.vue';
import { 
    createLoadable, 
    toggleLoadable,
    setLoadableResponse,
    isResponseSuccesful,
} from '@/utils/loadable.js';
import { ConfirmationModalComponent } from '@/components/utils';

export default {
    name: 'UpsertProductModal',
    components: {
    GeneralidadesStep,
    PhotoEditForm,
    ConfirmationModalComponent
},
    props: {
        isOpen: { type: Boolean },
        product: { type: Object },
        editting: { type: Boolean },
        cargando: { type: Boolean },
    },
    emits: ['close', 'save', 'on-success'],
    data: () => ({
        formsData: {},
        steps: 1,
        producto: createLoadable([]),
        isConfirmModalOpen: false,
    }),
    computed: {
        isEdit() {
            return Boolean(this.$props.product);
        },
        stepsOrder () {
            return ({
                generalidades: 1,
                fotografia: this.isEdit ? 2 : null,
            });
        },
    },
    methods: {
        clearForms() {
            this.$refs.generalidadesRef?.clearForm();
            this.$refs.specsRef?.clearForm();
        },
        handleClose() {
            this.steps = 1;
            this.$emit('close');
            this.clearForms();
        },
        async save() {
            this.steps = 1;
            const formData = convertToFormData({...this.formsData});            
            toggleLoadable(this.producto);
            const { data } = await this.services.Product.upsertProduct(formData, this.product?.id);
            setLoadableResponse(this.producto, data, { showAlertOnSuccess: true, skipOnSuccess: true });
            this.isConfirmModalOpen = false;

            if (isResponseSuccesful(data)) {
                this.formsData = {};
                this.clearForms();
                this.$emit('on-success');
            }

            this.handleClose();
        },
        confirmarAccion() {
            this.isConfirmModalOpen = true;
        },
        moveNextStep(data) {
            this.steps++;
            this.formsData = { ...this.formsData, ...data };
        },
        movePreviousStep() {
            this.steps--;
        },
        handleCreation(data) {
            this.formsData = { ...this.formsData, ...data };
            this.confirmarAccion();
        },
        finishSteps(data) {
            this.formsData = { ...this.formsData, ...data };
            this.confirmarAccion();
        },
    },
    watch:{
        isOpen(value){
            if(!value) this.steps = 1
        }
    }
}
</script>
<style>
    .upsert-product-modal {
        width: 100%;
        background-color: #FFF;
    }
</style>