<template>
    <v-dialog persistent max-width="800" class="modal" v-model="isOpen">
        <div class="upsert-product-modal position-relative px-4" style="position: relative;">
            <div class="py-4 d-flex align-center" style="position: sticky; top: 0; left: 0; background-color: #FFF;">
            <v-btn :disabled="isLoading" @click="close" icon color="secondary">
                <v-icon>mdi-window-close</v-icon>
            </v-btn>
            <p class="text-h6 ml-4 mb-0 d-inline-block text-uppercase font-weight-bold">
                {{ editting ? "Editar" : "Agregar" }} subgrupo
                </p>
            </div>
            <v-form class="pb-4" ref="form" @submit.prevent="handleSubmit">
                <v-stepper v-model="steps">
                    <v-stepper-header>
                        <v-stepper-step 
                            :complete="steps > stepsOrder.generalidades" 
                            :step="stepsOrder.generalidades" 
                        >
                            Generalidades
                        </v-stepper-step>
                        <v-divider></v-divider>
                        <v-stepper-step
                            :complete="steps > stepsOrder.obs" 
                            :step="stepsOrder.obs" 
                        >
                            OBS
                        </v-stepper-step>
                        <v-divider></v-divider>
                        <v-stepper-step
                            :complete="steps > stepsOrder.inputs" 
                            :step="stepsOrder.inputs" 
                        >
                            Inputs
                        </v-stepper-step>
                        <v-divider></v-divider>
                        <v-stepper-step
                            :complete="steps > stepsOrder.categoria" 
                            :step="stepsOrder.categoria"
                        >
                        Especificaciones técnicas
                        </v-stepper-step>
                    </v-stepper-header>
                    <v-stepper-content class="px-4 py-4" :step="stepsOrder.generalidades">
                        <div class="d-flex justify-center align-center flex-wrap flex-md-nowrap mt-4">
                            <v-text-field 
                                v-model="form.codigo"
                                label="Codigo subgrupo*"
                                outlined 
                                :error-messages="codigoErrors"
                                style="width: 100%"
                                @change="$v.form.codigo.$touch()" 
                                @blur="$v.form.codigo.$touch()"
                            />
                            <v-text-field 
                                v-model="form.nombre"
                                label="Nombre subgrupo*" 
                                outlined 
                                @change="$v.form.nombre.$touch()"
                                @blur="$v.form.nombre.$touch()" 
                                :error-messages="nombreErrors"
                                class="ml-md-4"
                                style="width: 100%"
                            />
                        </div>
                        <v-row>
                            <v-col cols="3" sm="3">
                                <v-text-field
                                label="Codigo familia"
                                outlined
                                readonly
                                v-model="familiaCodigo"
                                />
                            </v-col>

                            <v-col cols="3" sm="3">
                                <v-text-field
                                label="Codigo grupo"
                                outlined
                                readonly
                                v-model="grupoCodigo"
                                />
                            </v-col>
                            <v-col cols="6" sm="6">
                                <v-autocomplete 
                                    v-model="form.id_grupo"
                                    label="Grupo *" 
                                    outlined 
                                    :items="group_list" 
                                    item-value="id" 
                                    item-text="nombre"
                                    @change="$v.form.id_grupo.$touch()"
                                    @blur="$v.form.id_grupo.$touch()" 
                                    :error-messages="grupoErrors"
                                />
                            </v-col>
                        </v-row>
                        <v-row no-gutters>
                            <v-col cols="12">
                                <v-autocomplete 
                                    v-model="form.atributos" 
                                    multiple 
                                    chips
                                    outlined 
                                    label="Seleccione los atributos" 
                                    :error-messages="atributosErrors" 
                                    deletable-chips 
                                    :items="atributos" 
                                    item-text="nombre"
                                    item-value="id"
                                    @change="$v.form.atributos.$touch"
                                    @blur="$v.form.atributos.$touch"
                                />
                            </v-col>
                        </v-row>
                        <v-row no-gutters class="d-flex justify-center align-center" style="gap: 16px;">
                            <v-btn :disabled="isLoading" color="secondary" class="text-uppercase flex-grow-1" @click="close">Cancelar</v-btn>
                            <v-btn :disabled="isLoading" color="primary" @click.stop="steps = stepsOrder.obs" class="text-uppercase flex-grow-1">Siguiente</v-btn>
                        </v-row> 
                    </v-stepper-content>
                    <v-stepper-content class="px-4 py-4" :step="stepsOrder.obs">
                        <v-row class="mt-4 pb-16" no-gutters>
                            <v-col cols="12">
                                <ProgresiveLoadAutocomplete 
                                    v-model="form.id_obs"
                                    label="Obs" 
                                    placeholder="Seleccione un OBS"
                                    itemText="nombre" 
                                    itemValue="id" 
                                    :pageable="obs" 
                                    clearable
                                    @search="searchObs"
                                    @load-more="loadMoreObs"
                                    @change="$v.form.id_obs.$touch()"
                                    @blur="$v.form.id_obs.$touch()"
                                    :error-messages="id_obsErrors"
                                />
                            </v-col>
                        </v-row>
                        <v-row no-gutters class="d-flex justify-center align-center" style="gap: 16px;">
                            <v-btn :disabled="isLoading" color="secondary" class="text-uppercase flex-grow-1" @click="goToPreviousStep">Atrás</v-btn>
                            <v-btn :loading="isLoading"  color="primary" @click.stop="steps = stepsOrder.inputs" class="text-uppercase flex-grow-1">
                                Siguiente
                            </v-btn>
                        </v-row>
                    </v-stepper-content>
                    <v-stepper-content class="px-4 py-4" :step="stepsOrder.inputs">
                        <v-row class="mt-4 pb-16" no-gutters>
                            <v-col cols="12">
                                <v-card class="px-4 py-4 pb-4">
                                    <v-card-title class="d-flex align-center">
                                        <v-icon class="mr-2">mdi-form-textbox</v-icon>
                                        <span class="font-weight-bold mr-2">Inputs que se mostraran en la creación del carrito</span>
                                    </v-card-title>
                                    <v-row class="align-center">
                                        <v-col cols="12" class="mt-4">
                                            <v-row no-gutters class="mb-4 align-center">
                                                <v-col cols="4">
                                                    <span class="primary--text font-weight-bold">Altura:</span>
                                                    <v-switch 
                                                        color="success" 
                                                        :ripple="false" 
                                                        :disabled="form.altura_decimal || form.monto || form.vigencia"
                                                        v-model="form.altura"
                                                    />
                                                </v-col>
                                                <v-col cols="4">
                                                    <span class="primary--text font-weight-bold">Base:</span>
                                                    <v-switch 
                                                        color="success" 
                                                        :ripple="false" 
                                                        v-model="form.base" 
                                                        :disabled="form.base_decimal || form.monto || form.vigencia"
                                                    />
                                                </v-col>
                                                <v-col cols="4">
                                                    <span class="primary--text font-weight-bold">Cantidad:</span>
                                                    <v-switch 
                                                        color="success" 
                                                        :ripple="false" 
                                                        v-model="form.cantidad"
                                                        :disabled="form.cantidad_decimal"

                                                    />
                                                </v-col>
                                                <v-col cols="4">
                                                    <span class="primary--text font-weight-bold">Altura decimal:</span>
                                                    <v-switch 
                                                        color="success" 
                                                        :ripple="false"
                                                        :disabled="form.altura || form.monto || form.vigencia"
                                                        v-model="form.altura_decimal"
                                                    />
                                                </v-col>
                                                <v-col cols="4">
                                                    <span class="primary--text font-weight-bold">Base decimal:</span>
                                                    <v-switch 
                                                        color="success" 
                                                        :ripple="false"
                                                        v-model="form.base_decimal" 
                                                        :disabled="form.base || form.monto || form.vigencia"
                                                    />
                                                </v-col>
                                                <v-col cols="4">
                                                    <span class="primary--text font-weight-bold">Cantidad decimal:</span>
                                                    <v-switch 
                                                        color="success" 
                                                        :ripple="false" 
                                                        v-model="form.cantidad_decimal" 
                                                        :disabled="form.cantidad"
                                                    />
                                                </v-col>
                                                <v-col cols="4">
                                                    <span class="primary--text font-weight-bold">Dirección:</span>
                                                    <v-switch 
                                                        color="success" 
                                                        :ripple="false"
                                                        v-model="form.direccion"
                                                    />
                                                </v-col>
                                                <v-col cols="4">
                                                    <span class="primary--text font-weight-bold">Manejo existencia:</span>
                                                    <v-switch color="success" 
                                                        :ripple="false" 
                                                        v-model="form.manejo_existencias" 
                                                    />
                                                </v-col>
                                                <v-col cols="4">
                                                    <span class="primary--text font-weight-bold">Líneas:</span>
                                                    <v-switch color="success" 
                                                        :ripple="false" 
                                                        v-model="form.lineas" 
                                                        :disabled="form.monto"
                                                    />
                                                </v-col>
                                                <v-col cols="4">
                                                    <span class="primary--text font-weight-bold">Hora:</span>
                                                    <v-switch color="success" 
                                                        :ripple="false" 
                                                        v-model="form.hora" 
                                                    />
                                                </v-col>
                                                <v-col cols="4">
                                                    <span class="primary--text font-weight-bold">Fecha:</span>
                                                    <v-switch color="success" 
                                                        :ripple="false" 
                                                        v-model="form.fecha" 
                                                    />
                                                </v-col>
                                                <v-col cols="4">
                                                    <span class="primary--text font-weight-bold">Pdf:</span>
                                                    <v-switch color="success" 
                                                        :ripple="false" 
                                                        v-model="form.pdf" 
                                                    />
                                                </v-col>
                                                <v-col cols="4">
                                                    <span class="primary--text font-weight-bold">Monto:</span>
                                                    <v-switch color="success" 
                                                        :ripple="false" 
                                                        v-model="form.monto" 
                                                        @change="handleSwitchChange"
                                                    />
                                                </v-col>
                                                <v-col cols="4">
                                                    <span class="primary--text font-weight-bold">Vigencia:</span>
                                                    <v-switch color="success" 
                                                        :ripple="false"
                                                        v-model="form.vigencia" 
                                                    />
                                                </v-col>
                                                <v-col cols="4">
                                                    <span class="primary--text font-weight-bold">Cifra:</span>
                                                    <v-switch color="success" 
                                                        :ripple="false" 
                                                        v-model="form.cifra" 
                                                    />
                                                </v-col>
                                                
                                                <div class="d-flex justify-space-between" style="gap: 5px; width: 100%;">    
                                                    <v-text-field 
                                                        v-model="form.limite_altura"
                                                        label="Limite de altura"
                                                        outlined 
                                                        :error-messages="limiteAlturaErrors"
                                                        @input="$v.form.limite_altura.$touch()"
                                                        @blur="$v.form.limite_altura.$touch()"
                                                        :disabled="limiteAlturaDesactivada(form)"
                                                        style="width: 20%"
                                                        type="text"
                                                        @keydown="onKeyDown($event, 'limite_altura')"
                                                    />
                                                    <v-text-field 
                                                        v-model="form.limite_base"
                                                        label="Limite de base"
                                                        outlined 
                                                        :error-messages="limiteBaseErrors"
                                                        @input="$v.form.limite_base.$touch()"
                                                        @blur="$v.form.limite_base.$touch()"
                                                        :disabled="limiteBaseDesactivada(form)"
                                                        style="width: 20%"
                                                        type="text"
                                                        @keydown="onKeyDown($event, 'limite_base')"

                                                    />
                                                </div>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-card>
                            </v-col>
                        </v-row>
                        <v-row no-gutters class="d-flex justify-center align-center" style="gap: 16px;">
                            <v-btn :disabled="isLoading" color="secondary" class="text-uppercase flex-grow-1" @click="goToPreviousStep">Atrás</v-btn>
                            <v-btn :loading="isLoading"  color="primary" @click.stop="steps = stepsOrder.categoria" class="text-uppercase flex-grow-1">
                                Siguiente
                            </v-btn>
                        </v-row>
                    </v-stepper-content>
                    <v-stepper-content class="px-4 py-4" :step="stepsOrder.categoria">
                        <EspecificacionesStep 
                            :onClose="goToPreviousStep" 
                            :editItem="editItem"
                            @finish="finishSteps"
                            :isParentModalOpen="isOpen"
                            ref="specsRef"
                        />
                    </v-stepper-content>
                </v-stepper>
            </v-form>
        </div>
    </v-dialog>
</template>
<script>
import { createPageable, togglePageable, setPageableResponse, isResponseSuccesful, createLoadable } from "@/utils/loadable";
import { ProgresiveLoadAutocomplete } from '@/components/utils';
import { helpers, required } from "vuelidate/lib/validators";
import { Validator } from '@/utils/form-validation.js';
import { Observable, Listener } from '@/utils/observable.js';
import { Countdown } from '@/utils/countdown';
import EspecificacionesStep from '../producto/EspecificacionesForm.vue'
const txtField = helpers.regex(
    "txtArea",
    /^[A-Za-z0-9-&.,:;()À-ÿ\u00f1\u00d1 ]*$/
);

const limites = helpers.regex(
    "limites",
    /^\d+-\d+$/
);
const defaultPagination = { per_page: 50, page: 1, };
const initialFormState = {
    codigo: null,
    nombre: null,
    id_grupo: null,
    activo: null,
    id_obs: null,
    atributos: [],
    altura: null,
    base: false,
    cantidad: false,
    altura_decimal: false,
    base_decimal: false,
    cantidad_decimal: false,
    manejo_existencias: false,
    limite_altura: null,
    limite_base: null,
    direccion: false,
    hora: false,
    fecha: false,
    pdf: false,
    monto: false,
    lineas: null,
    vigencia: false,
    cifra: false,
};

export default {
    name: 'UpsertSubGrupo',
    emits: ['cancel', 'save'],
    components: { ProgresiveLoadAutocomplete, EspecificacionesStep },
    props: {
        isOpen: { type: Boolean },
        editting: { type: Boolean },
        editItem: { type: Object, required: false },
        isLoading: { type: Boolean },
    },
    data: () => ({
        atributos: [],
        group_list: [],
        habilitarInputs: createLoadable([]),
        form: { ...initialFormState },
        // OBS
        obs: createPageable([], 50),
        obsTerm: '',
        steps: 1,
        familiaCodigo: '',
        grupoCodigo: '',
    }),
    validations(){
        return ({
            form: {
                id_obs: { required },
                codigo: { required, txtField },
                nombre: { required, txtField },
                id_grupo: { required },
                atributos: { required },
                limite_altura: this.form.altura ? { required, limites } : this.form.altura_decimal ?  { required,limites  }: {  },
                limite_base: this.form.base  ? { required, limites } : this.form.base_decimal ? { required, limites } : {  },
            },
        });
    },
    computed: {
        esAltura(){
            return this.form.altura || this.form.altura_decimal
        },
        esBase(){
            return this.form.base || this.form.base_decimal
        },
        id_obsErrors() {
            return new Validator(this.$v.form.id_obs).detect().getResult();
        },
        nombreErrors() {
            return new Validator(this.$v.form.nombre).detect().getResult();
        },
        codigoErrors() {
            return new Validator(this.$v.form.codigo).detect().getResult();
        },
        grupoErrors() {
            return new Validator(this.$v.form.id_grupo).detect().getResult();
        },
        atributosErrors() {
            return new Validator(this.$v.form.atributos).detect().getResult();
        },
        limiteAlturaErrors() {
            const errors = [];
            const altura = this.form.altura || this.form.altura_decimal
            if (!this.$v.form.limite_altura.$dirty ) return errors;
            altura && !this.$v.form.limite_altura.required && errors.push("Campo requerido");
            if (!this.$v.form.limite_altura.limites && altura) {
                errors.push("El formato debe ser un número seguido de un guion y otro número, por ejemplo, 1-9.");
            }
            return errors;
        },

        limiteBaseErrors() {
            const errors = [];
            const base = this.form.base || this.form.base_decimal
            if (!this.$v.form.limite_base.$dirty) return errors;
            base && !this.$v.form.limite_base.required && errors.push("Campo requerido");
            if (!this.$v.form.limite_base.limites && base) {
                errors.push("El formato debe ser un número seguido de un guion y otro número, por ejemplo, 1-9.");
            }
            return errors;
        },
        stepsOrder () {
            return ({
                generalidades: 1,
                obs: 2,
                inputs: 3,
                categoria: 4,
            });
        },
    },
    methods: {
        onKeyDown(event, fieldName) {
            const regex = /^\d+(-\d*)?$/;
            const fieldValue = this.form[fieldName] || ''; 
            const value = fieldValue + event.key;

            if (
                !regex.test(value) &&
                event.key !== 'Backspace' &&
                event.key !== 'Delete' &&
                !(event.ctrlKey && event.key === 'a')
            ) {
                event.preventDefault();
            }
        },
        goToPreviousStep() {
            this.steps--;
        },
        async getGrupos() {
            const {status, data} = await this.services.CmGrupos.getGruposList({
                pagination: false,
            });

            if (status == 200) this.group_list = data.data;
        },
        async getAtributos() {
            const {status, data, headers} = await this.services.cmAtributos.listatributo({ pagination: false });
            this.atributos = data.data
        },
        async getFamilias() {
            const { status, data } = await this.services.CmGrupos.getFamiliasList({
                pagination: false,
            });

            if (status == 200) {
                this.familias_list = data.data;
            }
        },
        // Obs
        async fetchObs(pagination = defaultPagination) {
            togglePageable(this.obs);
            const itemsCopy = this.obs.data;
            const { data, headers } = await this.services.Obs.getObs({
                pagination: true,
                ...pagination,
                nombre: this.obsTerm,
            });

            setPageableResponse(this.obs, data, headers, { skipOnSuccess: true, persistDataOnError: true });

            if (!isResponseSuccesful(data)) return;
            this.obs.data = pagination.page > 1 ? itemsCopy.concat(data.data) : data.data;
        },
        async searchObs(term) {
            if (this.obsTerm === term) return;
            this.obsTerm = term;
            this.fetchObs();
        },
        loadMoreObs() {
            if (this.obs.isLoading) return;
            const { page, per_page, total_rows } = this.obs.pagination;
            const currentlyLoaded = page * per_page;

            if (!(currentlyLoaded < total_rows)) return;
            this.fetchObs({ page: page + 1, per_page });
        },
        handleSubmit() {
            this.$v.$touch();

            if (this.$v.$invalid) {
                const firstFormFields = [['nombre', 'codigo', 'id_grupo'], ['id_obs'], ['limite_altura', 'limite_base']];
                const errorFieldGroup = firstFormFields.find((fields) => fields.some((field) => this.$v.form[field]?.$error));

                if (errorFieldGroup) {
                    const errorIndex = firstFormFields.indexOf(errorFieldGroup) + 1;
                    return this.steps = errorIndex;
                } 
                return;
            }
            this.$emit('save', { ...this.form });
            this.$v.$reset();
        },
        close() {
            this.$v.$reset();
            this.form = { ...initialFormState };
            this.$emit('close');
        },
        updateCodigoField() {
            if (this.form.id_grupo) {
                const grupo = this.group_list.find(f => f.id === this.form.id_grupo);
                const familia = this.familias_list.find(f => f.id === grupo.id_familia);
                if (familia && grupo) {
                    this.grupoCodigo = `${grupo.codigo}`;
                    this.familiaCodigo = `${familia.codigo}`;
                }
            }
        },
        finishSteps(categoria) {
            this.form = { ...this.form, ...categoria };
            this.handleSubmit();
        },
        limiteAlturaDesactivada(item) {
            if(!item.altura && !item.altura_decimal)  return this.form.limite_altura = '';

            return !!(!item.altura && !item.altura_decimal);
        },
        limiteBaseDesactivada(item){
            if (!item.base && !item.base_decimal) return this.form.limite_base = '';
            return !!(!item.base && !item.base_decimal);
        },
        async obtenerMetodos(){
            this.getGrupos();
            this.getFamilias();
        },
        handleSwitchChange() {
            if (this.form.monto) {
                this.form.lineas = false;
                this.form.altura = false;
                this.form.altura_decimal = false;
                this.form.base = false;
                this.form.base_decimal = false;
            }
        }
    },
    watch: {
        isOpen(value) {
            if (!value) {
                this.steps = 1;
                return;
            };
            if (this.editItem) {
                this.form.codigo = this.editItem.codigo;
                this.form.nombre = this.editItem.nombre;
                this.form.id_grupo = this.editItem.CmGrupo.id;
                this.form.activo = this.editItem.activo;
                this.form.atributos = (this.editItem.atributos || []).map(item => item.id_atributo);
                this.searchObs(this.editItem.Ob.nombre).then(() => {
                    this.form.id_obs = this.editItem.Ob.id;
                });
                this.form.altura = this.editItem.altura;
                this.form.base = this.editItem.base;
                this.form.altura_decimal = this.editItem.altura_decimal;
                this.form.base_decimal = this.editItem.base_decimal;
                this.form.direccion = this.editItem.direccion;
                this.form.cantidad = this.editItem.cantidad;
                this.form.manejo_existencias = this.editItem.manejo_existencias;
                this.form.cantidad_decimal = this.editItem.cantidad_decimal;
                this.form.limite_altura = this.editItem.limite_altura;
                this.form.limite_base = this.editItem.limite_base;
                this.form.hora = this.editItem.hora;
                this.form.fecha = this.editItem.fecha;
                this.form.pdf = this.editItem.pdf;
                this.form.monto = this.editItem.monto;
                this.form.lineas = this.editItem.lineas;
                this.form.vigencia = this.editItem.vigencia;
                this.form.cifra = this.editItem.cifra;
            } else {
                this.form = initialFormState;
                this.obsTerm = '';
                this.fetchObs();
            }
        },
        'form.id_grupo': {
            handler: 'updateCodigoField',
        },
    },
    created() {
        this.obtenerMetodos();
        this.getAtributos();
    },
    mounted() {
    const listener = new Listener('famComponent').setHandler(() => this.obtenerMetodos());
    this.unsubscribe = Observable.getInstance().subscribe('refresh-metodos-cm', listener);

    const listener2 = new Listener('grupoComponent').setHandler(() => this.getGrupos());
    this.unsubscribe = Observable.getInstance().subscribe('refresh-grupo-cm', listener2);
    },
    beforeDestroy() {
        Countdown.getInstance().clear();
        this.unsubscribe?.();
    },
}
</script>