<template>
    <Fragment>
      <v-dialog v-model="isOpen" persistent width="1600">
        <div
          class="upsert-product-modal pb-8 pt-16 px-8 position-relative"
          style="position: relative"
        >
          <v-btn
            @click="close"
            icon
            color="secondary"
            style="position: absolute; top: 16px; left: 16px"
          >
            <v-icon>mdi-window-close</v-icon>
          </v-btn>
  
          <v-alert v-if="cargandoPrecios" class="text-center">
            <p class="pb-0 mb-4">Cargando oferta...</p>
            <v-progress-linear
              :size="40"
              :width="3"
              :indeterminate="true"
              color="primary"
              class="mx-auto mt-4"
            ></v-progress-linear>
          </v-alert>

          <div v-else>
            <v-card>
                <v-card-title style="gap: 8px" class="align-center flex-wrap">
                    <v-icon style="font-size: 30px;">mdi-format-list-group</v-icon>
                    <span class="text-h6 text-uppercase font-weight-bold"
                    >Formulario ofertas</span
                >
                </v-card-title>
              
              <v-card-text>
                <v-btn
                  dark
                  color="primary"
                  class="cm-producto-btn flex-grow-1 flex-sm-grow-0 flex-shrink-1 mb-4"
                  @click="abrirAgregar()"
                >
                  Agregar
                </v-btn>
  
                <EmptyComponent
                  :empty="noTieneOferta && !cargandoPrecios"
                  colored
                  text="No se encuentran ofertas"
                  class="mt-4"
                >
                  <template v-slot:icon="{ className }">
                    <v-icon :class="className">mdi-account-off</v-icon>
                  </template>
                </EmptyComponent>
  
                <v-expansion-panels multiple accordion>
                  <v-expansion-panel
                    v-for="precio in items"
                    :key="precio.id"
                    class="mb-4"
                  >
                    <v-expansion-panel-header>
                      <div class="d-flex align-center w-100">
                        <div class="flex-grow-1 d-flex align-center">
                          <span
                            class="font-weight-bold title"
                            style="
                              font-size: 18px !important;
                              line-height: 24px !important;
                            "
                          >
                            {{ precio.proveedor.nombre_comercial }}
                          </span>
                          <v-text-field
                            v-model="precio.stock"
                            type="number"
                            class="mx-4"
                            style="max-width: 100px"
                            solo
                            outlined
                            dense
                            hide-details
                            @click.stop
                          />
                        </div>
                        <v-btn
                          @click="confirmarActualizacionOferta(precio)"
                          color="primary white--text mr-2"
                        >
                          Guardar
                        </v-btn>
                      </div>
                    </v-expansion-panel-header>
  
                    <v-expansion-panel-content>
                      <div style="max-height: 500px; overflow-y: auto">
                        <v-data-table
                          :headers="precioHeaders"
                          :items="precio.ofertas"
                          hide-default-footer
                          disable-pagination
                        >
                          <template v-slot:item.precio="{ item }">
                            <div
                              class="d-flex justify-center"
                              style="width: 150px"
                            >
                              <v-text-field
                                v-model="item.precio"
                                outlined
                                dense
                                hide-details
                                class="text-center"
                                type="number"
                              />
                            </div>
                          </template>
  
                          <template v-slot:item.marca="{ item }">
                            <div
                              class="d-flex justify-center"
                              style="width: 150px"
                            >
                              <v-text-field
                                v-model="item.marca"
                                outlined
                                dense
                                hide-details
                                class="text-center"
                                type="text"
                              />
                            </div>
                          </template>
  
                          <template v-slot:item.unidad_medida="{ item }">
                            <div
                              class="d-flex justify-center"
                              style="width: 150px"
                            >
                              <v-autocomplete
                                v-model="item.id_unidad_medida"
                                label="Unidad de medida"
                                dense
                                hide-details
                                :items="unidad_list.data"
                                item-text="nombre"
                                item-value="id"
                                outlined
                              />
                            </div>
                          </template>
  
                          <template v-slot:item.precio_con_iva="{ item }">
                            <div class="d-flex align-center">
                              <v-checkbox
                                v-model="item.precio_con_iva"
                                outlined
                                dense
                                hide-details
                                color="primary"
                              />
                              <v-chip color="primary" label outlined>
                                {{
                                  !!item.precio_con_iva ? "CON IVA" : "SIN IVA"
                                }}
                              </v-chip>
                            </div>
                          </template>
  
                          <template v-slot:item.actions="{ item }">
                            <div class="d-flex justify-center">
                              <v-icon
                                x-small
                                class="ml-2"
                                color="primary"
                                @click.stop="confirmarEliminacionOferta(item)"
                              >
                                mdi-delete
                              </v-icon>
                              <v-icon
                                x-small
                                class="ml-2"
                                color="primary"
                                @click.stop="abrirModal(precio, item)"
                              >
                                mdi-plus-thick
                              </v-icon>
                              <v-icon
                                x-small
                                class="ml-2"
                                color="primary"
                                @click.stop="
                                  abrirModalGastosAdministrativos(item)
                                "
                              >
                                mdi-cash-register
                              </v-icon>
                            </div>
                          </template>
                        </v-data-table>
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-card-text>
            </v-card>
          </div>
        </div>
      </v-dialog>
      <CmOfertaModal
        :isOpen="modalAgregar"
        @close="modalAgregar = false"
        :producto="producto"
        :proveedores="proveedor_list"
        :unidad="unidad_list"
        :configuraciones="configuraciones"
        @buscarProveedor="buscarProveedor"
        @cargarMasProveedores="cargarMasProveedores"
        @cargarUnidadesMedida="cargarUnidadesMedida"
        @on-save="onSaveOffer"
      />
      <CmDireccionModal
        :isOpen="modalDireccion"
        @close="modalDireccion = false"
        :oferta="activeOferta"
        :direccion="direccion"
      />
      <CmRegionModal
        :isOpen="modalRegion"
        @close="modalRegion = false"
        :oferta="activeOferta"
      />
      <cmGastosAdministrativos
        :isOpen="modalCombustible"
        @close="modalCombustible = false"
        :oferta="activeOferta"
        :gastos="listadoConfiguracionGastos"
        :sku="listaSku"
        @buscarSku="buscarSku"
        @cargarMasSku="cargarMasSku"
      />
      <ConfirmationModalComponent
        :isOpen="modalConfirmacionAbierta"
        description="¿Desea continuar con la acción?"
        @confirm="actualizarOferta"
        @cancel="modalConfirmacionAbierta = false"
        :isLoading="upsertAction.isLoading"
      />
      <ConfirmationModalComponent
        :isOpen="modalConfirmacionEliminar"
        description="¿Desea continuar con la acción?"
        @confirm="eliminarOferta"
        @cancel="modalConfirmacionEliminar = false"
        :isLoading="deleteAction.isLoading"
      />
    </Fragment>
  </template>
<script>
import ConfirmationModalComponent from '@/components/utils/ConfirmationModalComponent.vue';
import CmDireccionModal from './CmDireccionModal.vue';
import CmOfertaModal from './CmOfertaModal.vue';
import CmRegionModal from './cmRegionModal.vue';
import CmGastosAdministrativos from './CmGastosAdministrativos.vue'

import { 
    createLoadable, 
    createPageable,
    togglePageable,
    setPageableResponse,
    toggleLoadable,
    setLoadableResponse,
    isResponseSuccesful,
} from '@/utils/loadable.js';
import { mapActions } from "vuex";
import { convertToFormData } from '@/utils/data';
import EmptyComponent from '@/components/utils/EmptyComponent.vue';
import SpinnerComponent from '@/components/utils/SpinnerComponent.vue';

export default {
    name: 'CmPrecioModal',
    components: { ConfirmationModalComponent, CmDireccionModal, CmOfertaModal, CmRegionModal, CmGastosAdministrativos,EmptyComponent,SpinnerComponent },
    data: () => ({
        deleteAction: createLoadable(null),
        upsertAction: createLoadable(null),
        unidad_list: createLoadable([]),
        proveedor_list: createPageable([], 30),
        listaSku: createPageable([], 30),
        filtroProveedor: '',
        filtroSku: '',
        modalDireccion: false,
        modalRegion: false,
        modalAgregar: false,
        modalConfirmacionAbierta: null,
        modalConfirmacionEliminar: null,
        ofertaActiva: [],
        activeOferta:null,
        modalCombustible: false,
        listadoConfiguracionGastos: [],
        precioHeaders: [
            { align: 'center', sortable: false, text: 'Precio', value: 'precio' },
            { align: 'center', sortable: false, text: 'Marca', value: 'marca' },
            { align: 'center', sortable: false, text: 'Unidad de medida', value: 'unidad_medida' },
            { align: 'center', sortable: false, text: 'Valor', value: 'precio_con_iva' },
            { align: 'center', sortable: false, text: 'Acciones', value: 'actions' },
        ],
        paginacionProveedores: {
            pagina: 1,
            registrosPorPagina: 30,
        },
        paginacionSku: {
            pagina: 1,
            registrosPorPagina: 30,
        },
        configuraciones: createLoadable([]),
    }),
    props: {
        isOpen: { type: Boolean, },
        precios: { type: Object },
        cargando: { type: Boolean },
        producto: { type: Object },
        direccion: { type: Object}
    },
    emits: ['close', 'on-save'],
    computed: {
        items() {
            return this.precios.data;
        },
        cargandoPrecios(){
            return this.precios.isLoading;
        },
        noTieneOferta(){
            return !this.precios.data?.length > 0;
        },

    },
    methods: {
        ...mapActions("cmConvenioStore", ["cargarDirecciones","cargarRegiones"]),
        close() {
            this.$emit('close');
        },
        async eliminarOferta() {
            const oferta = this.ofertaActiva;
            
            toggleLoadable(this.deleteAction);
            const { data } = await this.services.cmProducto.deletePrecioProducto(oferta.id);
            setLoadableResponse(this.deleteAction, data, { showAlertOnSuccess: true, skipOnSuccess: true });
            if (isResponseSuccesful(data)) {
                this.$emit('refresh-ofertas', oferta.id_producto);
            }
            this.modalConfirmacionEliminar = false;
        },
        async actualizarOferta() {
            const oferta = this.ofertaActiva;

            const fromData = convertToFormData({
                ofertas: oferta.ofertas,
                stock: oferta.stock,
                id_producto: oferta.producto.id,
            });

            toggleLoadable(this.upsertAction);
            const { data } = await this.services.cmProducto.actualizarPrecioProducto(fromData);

            setLoadableResponse(this.upsertAction, data, { showAlertOnSuccess: true, skipOnSuccess: true });
            if (isResponseSuccesful(data)) {
                this.$emit('refresh-ofertas', oferta.producto.id);
            }
            this.modalConfirmacionAbierta = false;
        },
        confirmarActualizacionOferta(data) {
            this.ofertaActiva = data;
            this.modalConfirmacionAbierta = true;
       },
        confirmarEliminacionOferta(oferta) {
            this.ofertaActiva = oferta;
            this.modalConfirmacionEliminar = true;
        },
        abrirModal(precio, oferta) {
            const producto = precio.producto;
            this.activeOferta = oferta;

            if (producto.id_tipo_producto == 2) {
                this.modalDireccion = true;
                this.cargarDireccion(oferta.id);
            } else {
                this.modalRegion = true;
                this.cargarRegiones(oferta.id);
            }
        },
        async abrirModalGastosAdministrativos(oferta) {
            this.activeOferta = oferta;
            this.modalCombustible = true;
            this.listaGastosAdministrativos(oferta.id);
            this.listarSkuGastos();
        },
        abrirAgregar() {
            this.modalAgregar = true;
            this.obtenerProveedores();
            this.cargarUnidadesMedida();
            this.obtenerListadoConfiguracion(); 
        },
        async buscarProveedor(termino) {
            if (this.filtroProveedor === termino) return;
            this.filtroProveedor = termino;
            this.proveedor_list.data = [];
            this.paginacionProveedores.pagina = 1;
            this.obtenerProveedores();
        },
        async cargarMasProveedores() {
            if (this.proveedor_list.isLoading) return;
            const { page, per_page, total_rows } = this.proveedor_list.pagination;
            const currentlyLoaded = page * per_page;
            if (!(currentlyLoaded < total_rows)) return;

            this.paginacionProveedores.pagina = page + 1;
            this.paginacionProveedores.registrosPorPagina = per_page;
            this.obtenerProveedores();
        },
        async cargarUnidadesMedida(){
            toggleLoadable(this.unidad_list)
            const { data } = await this.services.cmProducto.obtenerUnidadesMedida();
            setLoadableResponse(this.unidad_list, data,);
        },
        async obtenerProveedores() {
            const filtros = { 
                pagination: true, 
                per_page: this.paginacionProveedores.registrosPorPagina,
                page: this.paginacionProveedores.pagina,
                busqueda: this.filtroProveedor,
            };

            const itemsCopy = this.proveedor_list.data;
            togglePageable(this.proveedor_list);
            const { data, headers} = await this.services.cmProducto.listProductoProveedor(this.producto.id, filtros);
            setPageableResponse(this.proveedor_list, data, headers, { skipOnSuccess: true, persistDataOnError: true });
            
            if (!isResponseSuccesful(data)) {
                // Hacemos rollback de la pagina en caso de error para volver a cargar la misma al scrollear
                const { pagina } = this.paginacionProveedores;
                this.paginacionProveedores.pagina = pagina > 1 ? pagina - 1 : 1; 
                return;
            };

            this.proveedor_list.data = filtros.page > 1 ? itemsCopy.concat(data.data) : data.data;
        },
        onSaveOffer(oferta) {
            this.$emit('on-save', oferta);
        },
        validacion() {
            let deshabilitar = false;
            this.precios.forEach(precio => {
                const manejoExistencia = precio.producto.CmSubGrupo.manejo_existencias;
                if (!manejoExistencia) {
                    deshabilitar = true;
                }
            });
            return deshabilitar;
        },

        async cargarDireccion(ofertaId) {
           await this.cargarDirecciones(ofertaId);
        },
        async cargarRegion(ofertaId) {
           await this.cargarRegiones(ofertaId);
        },
        async listaGastosAdministrativos(ofertaId){
            const { data } = await this.services.cmProducto.listarConfiguracionGastosAdministrativos(ofertaId);
            this.listadoConfiguracionGastos = data;
            
        },
        async buscarSku(termino) {
            if (this.filtroSku === termino) return;
            this.filtroSku = termino;
            this.listaSku.data = [];
            this.paginacionSku.pagina = 1;
            this.listarSkuGastos();
        },
        async cargarMasSku() {
            if (this.listaSku.isLoading) return;
            const { page, per_page, total_rows } = this.listaSku.pagination;
            const currentlyLoaded = page * per_page;
            if (!(currentlyLoaded < total_rows)) return;

            this.paginacionSku.pagina = page + 1;
            this.paginacionSku.registrosPorPagina = per_page;
            this.listarSkuGastos();
        },
        async listarSkuGastos() {
            const filtros = { 
                pagination: true, 
                per_page: this.paginacionSku.registrosPorPagina,
                page: this.paginacionSku.pagina,
                busqueda: this.filtroSku,
            };

            const itemsCopy = this.listaSku.data;
            togglePageable(this.listaSku);
            const { data, headers} = await this.services.cmProducto.listaSkuGastosAdministrativos(filtros);
            setPageableResponse(this.listaSku, data, headers, { skipOnSuccess: true, persistDataOnError: true });
            
            if (!isResponseSuccesful(data)) {
                // Hacemos rollback de la pagina en caso de error para volver a cargar la misma al scrollear
                const { pagina } = this.paginacionSku;
                this.paginacionSku.pagina = pagina > 1 ? pagina - 1 : 1; 
                return;
            };

            this.listaSku.data = filtros.page > 1 ? itemsCopy.concat(data.data) : data.data;
        },
        async obtenerListadoConfiguracion(){
            toggleLoadable(this.configuraciones)
            const { data } = await this.services.cmProducto.listarConfiguracionFamilia(this.producto.id)
            setLoadableResponse(this.configuraciones, data)
        }
    },
    created(){
        this.cargarUnidadesMedida();
    }
}
</script>