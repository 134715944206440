<template>
  <Fragment>
    <v-dialog v-model="isOpen" persistent width="1600">
      <div
        class="upsert-product-modal pb-8 pt-16 px-8 position-relative"
        style="position: relative"
      >
        <v-btn
          @click="cerrarModal"
          icon
          color="secondary"
          style="position: absolute; top: 16px; left: 16px"
        >
          <v-icon>mdi-window-close</v-icon>
        </v-btn>

        <v-card class="px-4 py-4 pb-4">
          <v-card-title class="d-flex align-center">
            <v-icon class="mr-2">mdi mdi-format-list-bulleted</v-icon>
            <span class="font-weight-bold mr-2">Gastos administrativos</span>
          </v-card-title>
          <div
            class="d-flex justify-space-between"
            style="gap: 5px; width: 100%"
          >
            <v-row>
              <v-col cols="8">
                <v-text-field
                  v-model="form.nombre"
                  label="Nombre del gasto administrativo"
                  outlined
                  :error-messages="nombreGastosAdministrativoErrors"
                  @input="$v.form.nombre.$touch()"
                  @blur="$v.form.nombre.$touch()"
                  type="text"
                />
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="form.precio"
                  label="Gasto administrativo"
                  outlined
                  :error-messages="gastosAdministrativoErrors"
                  @input="$v.form.precio.$touch()"
                  @blur="$v.form.precio.$touch()"
                  type="number"
                />
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="form.limite_inferior"
                  label="Desde"
                  outlined
                  :error-messages="limiteInferiorErrors"
                  @input="$v.form.limite_inferior.$touch()"
                  @blur="$v.form.limite_inferior.$touch()"
                  type="number"
                />
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="form.limite_superior"
                  label="Hasta"
                  outlined
                  :error-messages="limiteSuperiorError"
                  @input="$v.form.limite_superior.$touch()"
                  @blur="$v.form.limite_superior.$touch()"
                  type="number"
                />
              </v-col>
              <v-col cols="4" class="mt-2 mb-2">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      class="primary"
                      @click.stop="agregarGastos()"
                    >
                      <v-icon class="white--text">mdi-plus</v-icon>
                      Agregar
                    </v-btn>
                  </template>
                  Agregar
                </v-tooltip>
              </v-col>
              <v-col cols="6">
                <ProgresiveLoadAutocomplete
                      v-model="form.sku"
                      placeholder="Seleccione un SKU"
                      label="Sku"
                      outlined
                      :items="sku.data"
                      :loading="sku.isLoading"
                      :pageable="sku" 
                      item-value="id"
                      item-text="sku"
                      @search="buscarSku"
                      @load-more="cargarMasSku"
                      :error-messages="skuErrors"
                      @input="$v.form.sku.$touch()"
                      @blur="$v.form.sku.$touch()"
                      clearable
                  />
              </v-col>
              <v-col v-if="form.sku" cols="6">
                <v-autocomplete
                    v-model="form.id_sku_gasto"
                    label="Oferta"
                    dense
                    :items="listaOferta.data"
                    :item-text="item =>`${item.Proveedor.nombre_comercial} - $${item.precio}`"
                    item-value="id"
                    outlined
                    style="height: 100%;"
                    hide-details
                    :error-messages="skuGastoErrors"
                    @input="$v.form.id_sku_gasto.$touch()"
                    @blur="$v.form.id_sku_gasto.$touch()"
                />
              </v-col>
            </v-row>
          </div>
          <DataTableComponent
            :headers="gastosHeaders"
            class="mt-4 mb-4"
            :items="listarGastosAdministrativos"
            :tiene_paginacion="false"
          >
            <template v-slot:item.nombre_gasto="{ item }">
              {{ item.nombre }}
            </template>
            <template v-slot:item.gasto="{ item }">
              ${{ item.precio_gasto }}
            </template>
            <template v-slot:item.limite_inferior="{ item }">
              {{ item.limite_inferior }}
            </template>
            <template v-slot:item.limite_superior="{ item }">
              {{ item.limite_superior }}
            </template>
            <template v-slot:item.sku="{ item }">
              {{ `${item.sku} - ${item.precio}`}}
            </template>
            <template v-slot:item.acciones="{ item }">
              <!-- <v-btn icon @click="modificarGasto(item)">
                <v-icon>mdi-pencil</v-icon>
              </v-btn> -->
              <v-btn icon @click="eliminarGasto(item)">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
          </DataTableComponent>

          <v-row
            no-gutters
            class="d-flex justify-center align-center"
            style="gap: 16px"
          >
            <v-btn
              color="secondary"
              @click.stop="cerrarModal"
              class="text-uppercase flex-grow-1"
              >Cancelar</v-btn
            >
          </v-row>
        </v-card>
      </div>
    </v-dialog>

    <ConfirmationModalComponent
      :isOpen="modalConfirmacionEliminar"
      description="¿Desea continuar con la acción?"
      @confirm="confirmarEliminacion"
      @cancel="modalConfirmacionEliminar = false"
    />
    <!-- <ConfirmationModalComponent 
        :isOpen="modalConfirmacionEliminar" 
        description="¿Desea continuar con la acción?"
        @confirm="eliminarOferta" 
        @cancel="modalConfirmacionEliminar = false" 
        :isLoading="deleteAction.isLoading" 
    /> -->
  </Fragment>
</template>
<script>
import ConfirmationModalComponent from "@/components/utils/ConfirmationModalComponent.vue";
import CmDireccionModal from "./CmDireccionModal.vue";
import CmOfertaModal from "./CmOfertaModal.vue";
import CmRegionModal from "./cmRegionModal.vue";
import DataTableComponent from "../../../../components/DataTableComponent.vue";

import { Fragment } from "vue-fragment";
import { required } from "vuelidate/lib/validators";
import { Validator } from "@/utils/form-validation";
import ProgresiveLoadAutocomplete from "@/components/utils/ProgresiveLoadAutocomplete.vue";
import { createLoadable, setLoadableResponse, toggleLoadable } from "@/utils/loadable";

export default {
  name: "CmGastosAdministrativos",
  components: {
    ConfirmationModalComponent,
    CmDireccionModal,
    CmOfertaModal,
    CmRegionModal,
    DataTableComponent,
    ProgresiveLoadAutocomplete,
  },
  validations() {
    return {
      form: {
        nombre: { required },
        precio: { required },
        limite_inferior: { required },
        limite_superior: { required },
        sku: { required },
        id_sku_gasto: { required },
      },
    };
  },
  emits: ["close", "on-save"],
  data: () => ({
    listaOferta : createLoadable([]),
    form: {
      nombre: null,
      precio: null,
      limite_inferior: null,
      limite_superior: null,
      sku: null,
      id_sku_gasto:null,
    },
    seleccionSku: false,
    // cargandoAccion: false,
    modalConfirmacionEliminar: false,
    listarGastosAdministrativos: [],
    itemParaEliminar: null,
    gastosHeaders: [
        { align: 'center', sortable: false, text: 'Nombre', value: 'nombre_gasto' },
        { align: 'center', sortable: false, text: 'Gasto administrativo', value: 'gasto' },
        { align: 'center', sortable: false, text: 'Desde', value: 'limite_inferior' },
        { align: 'center', sortable: false, text: 'Hasta', value: 'limite_superior' },
        { align: 'center', sortable: false, text: 'Sku - Precio', value: 'sku' },
        { align: 'center', sortable: false, text: 'Acciones', value: 'acciones' },
    ],
  }),
  props: {
    isOpen: { type: Boolean },
    isLoading: { type: Boolean },
    gastos: { type: Array },
    oferta: { type: Object },
    sku: { type: Object },
  },
  computed: {
    limiteInferiorErrors() {
      return new Validator(this.$v.form.limite_inferior).detect().getResult();
    },
    limiteSuperiorError() {
      return new Validator(this.$v.form.limite_superior).detect().getResult();
    },
    gastosAdministrativoErrors() {
      return new Validator(this.$v.form.precio).detect().getResult();
    },
    nombreGastosAdministrativoErrors() {
      return new Validator(this.$v.form.nombre).detect().getResult();
    },
    skuErrors() {
      return new Validator(this.$v.form.nombre).detect().getResult();
    },
    skuGastoErrors() {
      return new Validator(this.$v.form.nombre).detect().getResult();
    },
  },
  methods: {
    async buscarSku(termino){
      this.$emit('buscarSku', termino)
    },        
    async cargarMasSku(){
      this.$emit('cargarMasSku')
    },    
    async agregarGastos() {
      this.$v.$touch();

      if (this.$v.$invalid) return;

      const data = {
        nombre: this.form.nombre,
        precio: this.form.precio,
        limite_inferior: this.form.limite_inferior,
        limite_superior: this.form.limite_superior,
        id_sku_gasto: this.form.id_sku_gasto
      };

      // Verifica si el gasto ya existe en la lista
      const existe = this.listarGastosAdministrativos.some((gasto) => {
        return (
          gasto.nombre === data.nombre &&
          gasto.precio === data.precio &&
          +gasto.limite_inferior === +data.limite_inferior &&
          +gasto.limite_superior === +data.limite_superior
        );
      });

      if (!existe) {
        const nuevoGasto = data;

        const { status } =
          await this.services.cmProducto.guardarConfiguracionGastoAdministrativo(
            this.oferta.id,
            nuevoGasto
          );

        if (status === 201) {
          this.listarGastosAdministrativos.push(data);
          this.cerrarModal();
        }

        // Limpiar el formulario y resetear la validación, independientemente del resultado
        this.form = {};
        this.$v.$reset();
      } else {
        this.temporalAlert({
          show: true,
          message: "Este gastos administrativo ya existe.",
          type: "warning",
        });
      }
    },
    //TODO Agregar funcionamiento de edicion.
    modificarGasto(item) {
      const index = this.listarGastosAdministrativos.indexOf(item);
      if (index !== -1) {
        this.listarGastosAdministrativos.splice(index, 1);
        this.form.precio = item.precio;
        this.form.limite_inferior = item.limite_inferior;
        this.form.limite_superior = item.limite_superior;
        this.form.nombre = item.nombre;
      }
    },
    eliminarGasto(item) {
      this.itemParaEliminar = item;
      this.modalConfirmacionEliminar = true;
    },
    async confirmarEliminacion() {
      if (this.itemParaEliminar) {
        const index = this.listarGastosAdministrativos.indexOf(
          this.itemParaEliminar
        );
        if (index !== -1) {
          this.listarGastosAdministrativos.splice(index, 1);
          await this.services.cmProducto.eliminarConfiguracionGastosAdministrativos(
            this.itemParaEliminar.id
          );
          this.itemParaEliminar = null;
        }
      }
      this.modalConfirmacionEliminar = false;
    },
    async obtenerOfertas(sku){
      toggleLoadable(this.listaOferta)
      const { data } = await this.services.cmProducto.cargarOfertasSKU({id_proveedor: this.oferta.id_proveedor, sku});
      
      setLoadableResponse(this.listaOferta, data);
    },

    limpiarFormulario() {
      this.form = {};
    },
    cerrarModal() {
      this.$emit("close");
      this.limpiarFormulario();
    },
  },
  created() {},
  watch: {
    gastos(value) {      
      this.listarGastosAdministrativos = value.map((gasto) => {
        return {
          id: gasto.id,
          nombre: gasto.nombre,
          precio_gasto: gasto.precio,
          limite_inferior: gasto.limite_inferior,
          limite_superior: gasto.limite_superior,
          sku: gasto.CMOfertaProductoConvenio.CMProducto.cmsku.sku,
          precio: gasto.CMOfertaProductoConvenio.precio
        };
      });
    },
    "form.sku"(value){
      if(!value) return;
      this.obtenerOfertas(value);
    }
  },
};
</script>
