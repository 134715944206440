<template>
  <section>
    <v-card class="px-4 py-4">
      <v-card-title class="px-0 py-0 mb-4 justify-end">
        <v-btn dark color="secondary" @click="gruposDialog = true">Agregar</v-btn>
      </v-card-title>
      <v-card-text class="px-0 py-0 mb-8">
        <data-table-component
          v-models:select="perPage"
          v-models:pagina="page"
          :headers="headers"
          :items="items"
          @paginar="paginate"
          :total_registros="totalRows"
        >
          <template v-slot:[`item.activo`]="{ item }">
              <v-chip :class="item.activo ? 'secondary ' : ''" :style="item.activo ? 'color:white !important' : ''" >
                {{ item.activo ? "Activo" : "Inactivo" }}
              </v-chip>
            </template>
          <template v-slot:[`item.acciones`]="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                    @click="editarGrupo(item)"
                    v-bind="attrs"
                    v-on="on"
                    large
                    color="primary"
                >
                  mdi-pencil
                </v-icon>
              </template>

              <span>Editar</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                    @click="(deleteDialog = true), (grupoSeleccionada = item.id)"
                    v-bind="attrs"
                    v-on="on"
                    large
                    color="primary"
                >
                  mdi-delete
                </v-icon>
              </template>

              <span>Eliminar</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    @click="(modalEstado = true), (grupoSeleccionada = item.id), (estado = item.activo)"

                    :disabled="
                            !haveRoles([
                              'ROLE_DIRECCION_COMPRAS',
                              'ROLE_TECNICO_DIRECCION_COMPRAS',
                            ])
                          "
                >
                  <v-icon>{{
                      item.activo
                          ? "mdi-lock-outline"
                          : "mdi-lock-open-outline"
                    }}</v-icon>
                </v-btn>
              </template>
              <span> Deshabilitar </span>
            </v-tooltip>


          </template>
        </data-table-component>
      </v-card-text>
    </v-card>
    
    <v-dialog persistent max-width="1000" class="modal" v-model="gruposDialog">
      <v-card max-width="1000" max-height="1050">
        <v-btn @click="clearInfo" icon color="secondary">
          <v-icon>mdi-window-close</v-icon>
        </v-btn>
        <v-card-text>
          <section>
            <v-row>
              <p class="text-h5 secondary--text mx-4">
                {{ editable ? "Editar" : "Agregar" }} grupo
              </p>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-autocomplete
                    label="Familia *"
                    outlined
                    :items="familias_list"
                    item-value="id"
                    item-text="nombre"
                    @blur="$v.grupoObj.id_familia.$touch()"
                    :error-messages="familiaError"
                    v-model="grupoObj.id_familia"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="2" sm="2">
                <v-text-field
                  label="Codigo familia"
                  outlined
                  readonly
                  v-model="familiaCodigo"
                />
              </v-col>
              <v-col cols="5" sm="5" md="5" lg="5">
                <v-text-field
                    label="Codigo grupo *"
                    outlined
                    v-model="grupoObj.codigo"
                    @input="$v.grupoObj.codigo.$touch()"
                    :error-messages="codigoError"
                />
              </v-col>
              <v-col cols="5" sm="5" md="5" lg="5">
                <v-text-field
                    label="Nombre grupo *"
                    outlined
                    v-model="grupoObj.nombre"
                    @input="$v.grupoObj.nombre.$touch()"
                    :error-messages="nombreError"
                />
              </v-col>

            </v-row>

          </section>
        </v-card-text>
        <v-card-actions>
          <v-btn
              color="secondary"
              class="text-capitalize mx-8 mb-2 mb-sm-0"
              outlined
              @click="clearInfo()"
          >
            Cancelar
          </v-btn>
          <v-btn
              color="secondary"
              @click="guardarGrupo"
              class="white--text mx-8 px-8 text-capitalize mt-2 mt-sm-0"
          >
            Agregar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <dialogoConfirmacion
        :show="modalEstado"
        btnConfirmar="Aceptar"
        :title="`¿Está seguro de ${
        grupoSeleccionada?.activo ? 'deshabilitar' : 'habilitar'
      } el grupo?`"
        message="Esta acción afectará la estructura de productos del catálogo"
        @close="modalEstado = false"
        @confirm="deshabilitar"
    />

    <dialogoConfirmacion
        :show="deleteDialog"
        title="¿Deseas remover esta grupo?"
        message="De confirmarse la siguiente acción, los cambios no serán reversibles."
        btnConfirmar="Aceptar"
        @close="(deleteDialog = false), (grupoSeleccionada = null)"
        @confirm="eliminarGrupo()"
    />
  </section>
</template>
<script>
import DataTableComponent from "../../../components/DataTableComponent.vue";
import { helpers, required } from "vuelidate/lib/validators";
import dialogoConfirmacion from "../../../components/ConfirmationDialogComponent.vue";
import { Validator } from '@/utils/form-validation.js';
import { Observable, Listener } from '@/utils/observable.js';
import { Countdown } from '@/utils/countdown';

const txtField = helpers.regex(
    "txtArea",
    /^[A-Za-z0-9-&.,:;()À-ÿ\u00f1\u00d1 ]*$/
);

export default {
  name: "GruposComponent",
  components: {
    DataTableComponent,
    dialogoConfirmacion,
  },
  data: () => ({
    headers: [
      {
        text: "Código familia",
        value: "CmFamilium.codigo",
        align: "center",
      },
        {
        text: "Código grupo",
        value: "codigo",
        align: "center",
      },
      {
        text: "Nombre",
        value: "nombre",
        align: "center",
      },

      {
        text: "Estado",
        value: "activo",
        align: "center",
      },
      {
        text: "Acciones",
        value: "acciones",
        align: "center",
        sortable: false,
      },
    ],
    items: [],
    totalRows: 0,
    page: 1,
    perPage: 10,
    gruposDialog: false,
    tabMenu: null,
    grupoObj: {
      id: null,
      codigo: null,
      nombre: null,
      id_familia: null,
    },
    deleteDialog: false,
    estado: false,
    modalEstado: false,
    grupoSeleccionada: null,
    editable: false,
    familias_list: [],
    familiaCodigo: '',
  }),
  validations: {
    grupoObj: {
      codigo: { required, txtField },
      nombre: { required, txtField },
      id_familia: { required },
    },
  },
  computed: {
    nombreError() {
      return new Validator(this.$v.grupoObj.nombre).detect().getResult();
    },
    codigoError() {
      return new Validator(this.$v.grupoObj.codigo).detect().getResult();
    },
    familiaError() {
      return new Validator(this.$v.grupoObj.id_familia).detect().getResult();
    },

  },
  methods: {
    async deshabilitar() {

      this.grupoObj.id = this.grupoSeleccionada;
      this.grupoObj.activo = !this.estado;

      const response = await this.services.CmGrupos.putGruposEstado(
          this.grupoObj.id,
          this.grupoObj
      ).catch(() => {

      });
      if (response?.status === 200) {
        this.temporalAlert({
          show: true,
          type: "success",
          message: response?.data.message,
        });
      }
      this.modalEstado = false;
      this.grupoSeleccionada = null;
      this.getGrupos();

    },
    async getGrupos() {
      const { status, data, headers } =
          await this.services.CmGrupos.getGruposList({
            page: this.page,
            per_page: this.perPage,
          });

      if (status == 200) {

        this.items = data.data;
        this.totalRows = Number(headers.total_rows);
      }
    },
    async getFamilias() {
      const { status, data } = await this.services.CmGrupos.getFamiliasList({
        pagination: false,
      });

      if (status == 200) {
        this.familias_list = data.data;
      }
    },
    paginate(params) {
      const { cantidad_por_pagina, pagina } = params;
      this.perPage = cantidad_por_pagina;
      this.page = pagina;

      this.getGrupos();
    },
    clearInfo() {
      this.grupoObj.id = null;
      this.grupoObj.codigo = null;
      this.grupoObj.nombre = null;
      this.grupoObj.id_familia = null;
      this.familiaCodigo = null;
      this.gruposDialog = false;
      this.editable = false;
      this.$v.$reset();
    },
    async guardarGrupo() {

      this.$v.$touch();

      if (this.$v.$invalid) return;

      const response = await this.services.CmGrupos.upsertGroup(this.grupoObj, this.grupoObj.id);

      if (response.status == 201 || response.status == 200) {
        this.temporalAlert({
          show: true,
          message: `Grupo ${this.editable ? "editada" : "guardada"} con éxito`,
          type: "success",
        });
        this.clearInfo();
        this.getGrupos();
        Observable.emit("refresh-grupo-cm")
      }
    },
    editarGrupo(item) {
      this.grupoObj.id = item.id;
      this.grupoObj.codigo = item.codigo;
      this.grupoObj.nombre = item.nombre;
      this.grupoObj.id_familia = item.id_familia;

      this.editable = true;
      this.gruposDialog = true;
    },

    async eliminarGrupo() {
      const { status } = await this.services.CmGrupos.deleteGrupos(
          this.grupoSeleccionada
      );

      if (status == 200) {
        this.temporalAlert({
          show: true,
          message: `Grupo eliminada con éxito`,
          type: "success",
        });
        this.grupoSeleccionada = null;
        this.deleteDialog = false;
        this.getGrupos();
      }
    },
    updateCodigoField() {
      if (this.grupoObj.id_familia) {
        const familia = this.familias_list.find(f => f.id === this.grupoObj.id_familia);
        if (familia) {
            this.familiaCodigo = `${familia.codigo}`;
        } else {
          this.grupoObj.codigo = '';
        }
      }
    },
    async obtenerGruposYFamilias() {
       this.getGrupos();
       this.getFamilias();
  },
    
  },
  watch: {
    'grupoObj.id_familia': {
      handler: 'updateCodigoField',
    },
  },
  async created() {
    this.obtenerGruposYFamilias();
  },
  mounted() {
    const listener = new Listener('famComponent').setHandler(() => this.obtenerGruposYFamilias());
    this.unsubscribe = Observable.getInstance().subscribe('refresh-metodos-cm', listener);

  },
  beforeDestroy() {
    Countdown.getInstance().clear();
    this.unsubscribe?.();
  },
};
</script>
